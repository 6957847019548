import React ,{ useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './testimonial.css'

// import { gsap } from 'gsap';
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { NavLink } from 'react-router-dom';
//import { ScrollToPlugin } from "gsap/ScrollToPlugin"


import down from '../images/down-arrow.webp'
import testimg1 from '../images/Naman-Jain-test.webp';
import testimg2 from '../images/Mayank-Jain-test.webp';
import testimg3 from '../images/Pankaj-Thakur-test.webp';
import testimg4 from '../images/Ashish-Sharma-test.webp';
import testimg5 from '../images/Shubham-Dhingra-test.webp';
import testimg6 from '../images/Vatsal-Bass-test.webp';
import testimg7 from '../images/Anindita-Aggarwal-test.webp';
import testimg8 from '../images/Aryan-Tuteja-test.webp';
import testimg9 from '../images/Arpit-Katyal-test.webp';
import testimg10 from '../images/Ritika-Kapoor-test.webp';
import ExpandableText from './ExpandableText';

// gsap.registerPlugin(ScrollTrigger);





// const data = [
//   { id: 1, img: testimg1, name: 'Naman Jain', designation: "Senior Business Analyst", description: `"Working at PS has been a transformative experience. The supportive and collaborative culture has provided me with ample opportunities for professional and personal growth. A diverse range of projects along with interactions with the senior management has ingrained a solution-oriented attitude while also honing my technical as well as soft skills. The various activities and events provide the opportunity to interact with people across the firm while also providing the much-needed work-life balance."`},
//   { id: 2, img: testimg2, name: 'Mayank Jain', designation: "Senior Business Analyst", description: `"Stepping into PS has been a transformative experience for me. From starting as a Business Analyst to advancing to a Senior Business Analyst role, the opportunities for growth in both technical and soft skills have been remarkable. One of the most valuable parts of the job has been the chance to work closely with CXO-level clients, which has given me a deeper understanding of consulting and client management. Overall, it's been an enriching experience, where I've been able to learn, grow, and build meaningful professional relationships."`},
//   { id: 3, img: testimg3, name: 'Pankaj Thakur', designation: "Senior Business Analyst", description: `"I have embarked on my corporate and consulting career at Preferred Square. Throughout my tenure, I have not only honed my technical skills but also enhanced my ability to manage pressure and multitask effectively. My growth at Preferred Square is driven by a robust work culture, supportive team members, and highly skilled Managers and Consultants. I have been provided with the opportunities to work on diverse projects and engage with Executive-level clients. The Senior members have also encouraged me to assume greater responsibilities and lead client interactions, thereby fostering confidence and preparing me for future opportunities."`},
//   { id: 4, img: testimg4, name: 'Ashish Sharma', designation: "Senior Business Analyst", description: `"My experience at PS has been an exceptional journey of professional growth and continuous learning. I've had the opportunity to tackle diverse problem statements across multiple sectors. Working on various projects within the guidance of supportive seniors and managers has contributed a lot towards my personal and professional growth. The collaborative workspace and the individual attention the firm gives fosters team growth."`},
//   { id: 5, img: testimg5, name: 'Shubham Dhingra', designation: "Consultant", description: `"At PS, I've had the unique opportunity to work closely with large-scale investors and decision-makers, allowing me to actively contribute to significant economic transformations. This exposure has expanded my perspectives and capabilities, positioning me at the forefront of impactful projects. Being part of this dynamic environment, where my insights are valued, has been both empowering and rewarding, making me feel like I'm contributing to something bigger while continuously growing professionally."`},
//   { id: 6, img: testimg6, name: 'Vatsal Bass', designation: "Consultant", description: `"Embodying the principles of Simplicity, Quality, Resilience, & Excellence (Square), PS has imbibed within us the true spirit of management consulting. Our focus on providing simple & effective solutions, ascribed through meticulous process-oriented approach enables us to advise our clients across verticals and segments. Having embarked on this journey for almost half a decade with PS, I have immense gratitude for all the learnings received through various engagements. "`},
//   { id: 7, img: testimg7, name: 'Anindita Aggarwal', designation: "Senior Business Analyst", description: `"Joining Preferred Square as a Business Analyst has been a transformative experience. The steep learning curve has empowered me to expand my skills rapidly, and the quality of work we deliver is commendable. I particularly appreciate the opportunity to interact directly with CXO-level clients, which has deepened my insights into strategic business operations. The collaborative atmosphere and commitment to excellence at Preferred Square make it an ideal starting point for my career."`},
//   { id: 8, img: testimg8, name: 'Aryan Tuteja', designation: "Consultant", description: `"Joining Preferred Square has been a journey of growth in a vibrant, collaborative culture. The firm's commitment to client-focused solutions has helped me gain invaluable business insights, significantly deepening my knowledge and broadening my horizons. The on-site travel opportunities have enriched my experience, providing the chance to work closely with the CXO layer. As the saying goes, "Consulting is about shaping solutions, but it's also about shaping yourself"—Preferred Square exemplifies this every day."`},
//   { id: 9, img: testimg9, name: 'Arpit Katyal', designation: "Consultant", description: `"PS has been pivotal in both my professional & personal growth. The Company has nurtured my curiosity into fostering a solution-oriented mindset and appreciation for diverse perspectives. In the 2+ years I’ve been associated with PS, I’ve learned the importance of prioritizing our clients and the team over individual growth. I can proudly say that I found a family at PS, where we strive for excellence and aim to be leaders of our generation."`},
//   { id: 10, img: testimg10, name: 'Ritika Kapoor', designation: "Senior Business Analyst", description: `"PS has been instrumental in launching my career, offering a platform that fosters a steep learning curve and rapid development. Starting as a Business Analyst and advancing to a Senior Business Analyst, I have refined my technical and soft skills through front-end consulting and direct interactions with CXO-level clients. These experiences have been pivotal in shaping my professional journey. Additionally, participating in various events has enriched my skills and contributed to a balanced and dynamic work environment."`},
// ];


const data = [
  { id: 4, img: testimg4, name: 'Ashish Sharma', designation: "Senior Business Analyst", description: `"My experience at PS has been an exceptional journey of professional growth and continuous learning. I've had the opportunity to tackle diverse problem statements across multiple sectors. Working on various projects within the guidance of supportive seniors and managers has contributed a lot towards my personal and professional growth. The collaborative workspace and the individual attention the firm gives fosters team growth."`},
  { id: 6, img: testimg6, name: 'Vatsal Bass', designation: "Consultant", description: `"Embodying the principles of Simplicity, Quality, Resilience, & Excellence (Square), PS has imbibed within us the true spirit of management consulting. Our focus on providing simple & effective solutions, ascribed through meticulous process-oriented approach enables us to advise our clients across verticals and segments. Having embarked on this journey for almost half a decade with PS, I have immense gratitude for all the learnings received through various engagements. "`},
  { id: 9, img: testimg9, name: 'Arpit Katyal', designation: "Consultant", description: `"PS has been pivotal in both my professional & personal growth. The Company has nurtured my curiosity into fostering a solution-oriented mindset and appreciation for diverse perspectives. In the 2+ years I've been associated with PS, I've learned the importance of prioritizing our clients and the team over individual growth. I can proudly say that I found a family at PS, where we strive for excellence and aim to be leaders of our generation."`},
  { id: 1, img: testimg1, name: 'Naman Jain', designation: "Senior Business Analyst", description: `"Working at PS has been a transformative experience. The supportive and collaborative culture has provided me with ample opportunities for professional and personal growth. A diverse range of projects along with interactions with the senior management has ingrained a solution-oriented attitude while also honing my technical as well as soft skills. The various activities and events provide the opportunity to interact with people across the firm while also providing the much-needed work-life balance."`},
  { id: 2, img: testimg2, name: 'Mayank Jain', designation: "Senior Business Analyst", description: `"Stepping into PS has been a transformative experience for me. From starting as a Business Analyst to advancing to a Senior Business Analyst role, the opportunities for growth in both technical and soft skills have been remarkable. One of the most valuable parts of the job has been the chance to work closely with CXO-level clients, which has given me a deeper understanding of consulting and client management. Overall, it's been an enriching experience, where I've been able to learn, grow, and build meaningful professional relationships."`},
  { id: 3, img: testimg3, name: 'Pankaj Thakur', designation: "Senior Business Analyst", description: `"I have embarked on my corporate and consulting career at Preferred Square. Throughout my tenure, I have not only honed my technical skills but also enhanced my ability to manage pressure and multitask effectively. My growth at Preferred Square is driven by a robust work culture, supportive team members, and highly skilled Managers and Consultants. I have been provided with the opportunities to work on diverse projects and engage with Executive-level clients. The Senior members have also encouraged me to assume greater responsibilities and lead client interactions, thereby fostering confidence and preparing me for future opportunities."`},
  { id: 5, img: testimg5, name: 'Shubham Dhingra', designation: "Consultant", description: `"At PS, I've had the unique opportunity to work closely with large-scale investors and decision-makers, allowing me to actively contribute to significant economic transformations. This exposure has expanded my perspectives and capabilities, positioning me at the forefront of impactful projects. Being part of this dynamic environment, where my insights are valued, has been both empowering and rewarding, making me feel like I'm contributing to something bigger while continuously growing professionally."`},
  { id: 7, img: testimg7, name: 'Anindita Aggarwal', designation: "Senior Business Analyst", description: `"Joining Preferred Square as a Business Analyst has been a transformative experience. The steep learning curve has empowered me to expand my skills rapidly, and the quality of work we deliver is commendable. I particularly appreciate the opportunity to interact directly with CXO-level clients, which has deepened my insights into strategic business operations. The collaborative atmosphere and commitment to excellence at Preferred Square make it an ideal starting point for my career."`},
  { id: 8, img: testimg8, name: 'Aryan Tuteja', designation: "Consultant", description: `"Joining Preferred Square has been a journey of growth in a vibrant, collaborative culture. The firm's commitment to client-focused solutions has helped me gain invaluable business insights, significantly deepening my knowledge and broadening my horizons. The on-site travel opportunities have enriched my experience, providing the chance to work closely with the CXO layer. As the saying goes, "Consulting is about shaping solutions, but it's also about shaping yourself"—Preferred Square exemplifies this every day."`},
  { id: 10, img: testimg10, name: 'Ritika Kapoor', designation: "Senior Business Analyst", description: `"PS has been instrumental in launching my career, offering a platform that fosters a steep learning curve and rapid development. Starting as a Business Analyst and advancing to a Senior Business Analyst, I have refined my technical and soft skills through front-end consulting and direct interactions with CXO-level clients. These experiences have been pivotal in shaping my professional journey. Additionally, participating in various events has enriched my skills and contributed to a balanced and dynamic work environment."`},
];

const data1 = [
  { id: 1, img: testimg1, name: 'Naman Jain', designation: "Senior Business Analyst", wc:"350", description: `"Working at PS has been a transformative experience. The supportive and collaborative culture has provided me with ample opportunities for professional and personal growth. A diverse range of projects along with interactions with the senior management has ingrained a solution-oriented attitude while also honing my technical as well as soft skills. The various activities and events provide the opportunity to interact with people across the firm while also providing the much-needed work-life balance."`},
  { id: 4, img: testimg4, name: 'Ashish Sharma', designation: "Senior Business Analyst", wc:"80", description: `"My experience at PS has been an exceptional journey of professional growth and continuous learning. I've had the opportunity to tackle diverse problem statements across multiple sectors. Working on various projects within the guidance of supportive seniors and managers has contributed a lot towards my personal and professional growth. The collaborative workspace and the individual attention the firm gives fosters team growth."`},
  { id: 8, img: testimg8, name: 'Aryan Tuteja', designation: "Consultant", wc:"350", description: `"Joining Preferred Square has been a journey of growth in a vibrant, collaborative culture. The firm's commitment to client-focused solutions has helped me gain invaluable business insights, significantly deepening my knowledge and broadening my horizons. The on-site travel opportunities have enriched my experience, providing the chance to work closely with the CXO layer. As the saying goes, "Consulting is about shaping solutions, but it's also about shaping yourself"—Preferred Square exemplifies this every day."`},
];

const data2 = [
  { id: 2, img: testimg2, name: 'Mayank Jain', designation: "Senior Business Analyst",  wc:"80", description: `"Stepping into PS has been a transformative experience for me. From starting as a Business Analyst to advancing to a Senior Business Analyst role, the opportunities for growth in both technical and soft skills have been remarkable. One of the most valuable parts of the job has been the chance to work closely with CXO-level clients, which has given me a deeper understanding of consulting and client management. Overall, it's been an enriching experience, where I've been able to learn, grow, and build meaningful professional relationships."`},
  { id: 7, img: testimg7, name: 'Anindita Aggarwal', designation: "Senior Business Analyst",wc:"350", description: `"Joining Preferred Square as a Business Analyst has been a transformative experience. The steep learning curve has empowered me to expand my skills rapidly, and the quality of work we deliver is commendable. I particularly appreciate the opportunity to interact directly with CXO-level clients, which has deepened my insights into strategic business operations. The collaborative atmosphere and commitment to excellence at Preferred Square make it an ideal starting point for my career."`},
  { id: 5, img: testimg5, name: 'Shubham Dhingra', designation: "Consultant", wc:"80", description: `"At PS, I've had the unique opportunity to work closely with large-scale investors and decision-makers, allowing me to actively contribute to significant economic transformations. This exposure has expanded my perspectives and capabilities, positioning me at the forefront of impactful projects. Being part of this dynamic environment, where my insights are valued, has been both empowering and rewarding, making me feel like I'm contributing to something bigger while continuously growing professionally."`},
  { id: 9, img: testimg9, name: 'Arpit Katyal', designation: "Consultant", wc:"350", description: `"PS has been pivotal in both my professional & personal growth. The Company has nurtured my curiosity into fostering a solution-oriented mindset and appreciation for diverse perspectives. In the 2+ years I,ve been associated with PS, I,ve learned the importance of prioritizing our clients and the team over individual growth. I can proudly say that I found a family at PS, where we strive for excellence and aim to be leaders of our generation."`},

];

const data3 = [
  { id: 3, img: testimg3, name: 'Pankaj Thakur', designation: "Senior Business Analyst", wc:"350", description: `"I have embarked on my corporate and consulting career at Preferred Square. Throughout my tenure, I have not only honed my technical skills but also enhanced my ability to manage pressure and multitask effectively. My growth at Preferred Square is driven by a robust work culture, supportive team members, and highly skilled Managers and Consultants. I have been provided with the opportunities to work on diverse projects and engage with Executive-level clients. The Senior members have also encouraged me to assume greater responsibilities and lead client interactions, thereby fostering confidence and preparing me for future opportunities."`},
  { id: 10, img: testimg10, name: 'Ritika Kapoor', designation: "Senior Business Analyst", wc:"80", description: `"PS has been instrumental in launching my career, offering a platform that fosters a steep learning curve and rapid development. Starting as a Business Analyst and advancing to a Senior Business Analyst, I have refined my technical and soft skills through front-end consulting and direct interactions with CXO-level clients. These experiences have been pivotal in shaping my professional journey. Additionally, participating in various events has enriched my skills and contributed to a balanced and dynamic work environment."`},
  { id: 6, img: testimg6, name: 'Vatsal Bass', designation: "Consultant",wc:"350", description: `"Embodying the principles of Simplicity, Quality, Resilience, & Excellence (Square), PS has imbibed within us the true spirit of management consulting. Our focus on providing simple & effective solutions, ascribed through meticulous process-oriented approach enables us to advise our clients across verticals and segments. Having embarked on this journey for almost half a decade with PS, I have immense gratitude for all the learnings received through various engagements. "`},
];




  const Testimonial = () => {


    const [full, setFull] = useState(0);
    const limit = full === 0 ? 3 : data.length;
    

    
//   const scrollToTop = () => {
//     setTimeout(() => {
//     window.scrollTo(0, 0);
//     }, 100);
//     setTimeout(() => {
//     }, 600);
// }


  return (
    <>
      <section className='testimonial-section desktop' >
        <Container>
          <Row className='w-80 g-0'>
            <Col className='col-md-3 left-fixed'>
              <div className='content-box'>
                <div className='relative'>
                  <h2>Testimonial</h2>
                  <div className="strok">Testimonial</div>
                  <div className="h1 anima"> <span className='anima-underline'>Wall</span></div>
                </div>
              </div>

            </Col>

            <Col className='col-md-9 right-scroll'>

              <div className='scroll-section'>

                {/* <div className="scroll-page "> */}
                <div  className={(full === 1) ? 'scroll-page' : 'scroll-page single-row'} >
                  

                  <div className='testi-wrapper'>
                      <div className='testimonials'  data-masonry-options='{ "columnWidth": 280 }'>
                      {/* <div className={(full === 1) ? 'testimonials' : 'testimonials single-row'}  data-masonry-options='{ "columnWidth": 280 }'> */}


                          {data1.map((d, i) => (
                          // {data.slice(0, limit).map((d, i) => (

                          // <Col className={`col-md-4 ${d.id % 2 === 0 ? "mt-5" : "mt-1"}`} key={i}>
                          // <Col className="col-md-4 mt-3" key={i}>
                            <div className='testimonial' key={i}>
                                <div className='profile-img'>
                                  <img src={d.img}  alt='' />
                                </div>
                              <div className='gredient-border'>
                                    <div className='gredientbox'>
                                      <h4 className='name'>{d.name}</h4>
                                      <h6 className='designation'>{d.designation}</h6>
                                      {/* <p className='description'>{d.description}</p> */}
                                      <p className='description'><ExpandableText  text={d.description}  maxLength={d.wc} /></p>
                                  </div>
                                </div>

                            </div>
                          
                          // </Col>
                          ))}

                      </div>
                      


                      <div className='testimonials'  data-masonry-options='{ "columnWidth": 280 }'>
                      {/* <div className={(full === 1) ? 'testimonials' : 'testimonials single-row'}  data-masonry-options='{ "columnWidth": 280 }'> */}


                          {data2.map((d, i) => (
                          // {data.slice(0, limit).map((d, i) => (

                          // <Col className={`col-md-4 ${d.id % 2 === 0 ? "mt-5" : "mt-1"}`} key={i}>
                          // <Col className="col-md-4 mt-3" key={i}>
                            <div className='testimonial' key={i}>
                                <div className='profile-img'>
                                  <img src={d.img}  alt='' />
                                </div>
                              <div className='gredient-border'>
                                    <div className='gredientbox'>
                                      <h4 className='name'>{d.name}</h4>
                                      <h6 className='designation'>{d.designation}</h6>
                                      <p className='description'><ExpandableText  text={d.description}  maxLength={d.wc} /></p>
                                      </div>
                                </div>

                            </div>
                          
                          // </Col>
                          ))}

                      </div>


                      <div className='testimonials'  data-masonry-options='{ "columnWidth": 280 }'>
                      {/* <div className={(full === 1) ? 'testimonials' : 'testimonials single-row'}  data-masonry-options='{ "columnWidth": 280 }'> */}


                          {data3.map((d, i) => (
                          // {data.slice(0, limit).map((d, i) => (

                          // <Col className={`col-md-4 ${d.id % 2 === 0 ? "mt-5" : "mt-1"}`} key={i}>
                          // <Col className="col-md-4 mt-3" key={i}>
                            <div className='testimonial' key={i}>
                                <div className='profile-img'>
                                  <img src={d.img}  alt='' />
                                </div>
                              <div className='gredient-border'>
                                    <div className='gredientbox'>
                                      <h4 className='name'>{d.name}</h4>
                                      <h6 className='designation'>{d.designation}</h6>
                                      <p className='description'><ExpandableText  text={d.description}  maxLength={d.wc} /></p>
                                    </div>
                                </div>

                            </div>
                          
                          // </Col>
                          ))}

                      </div>
                  </div>
                  
                  <div style={{display:"none"}} className='testimonials'  data-masonry-options='{ "columnWidth": 280 }'>
                  {/* <div className={(full === 1) ? 'testimonials' : 'testimonials single-row'}  data-masonry-options='{ "columnWidth": 280 }'> */}


                      {data.map((d, i) => (
                      // {data.slice(0, limit).map((d, i) => (

                      // <Col className={`col-md-4 ${d.id % 2 === 0 ? "mt-5" : "mt-1"}`} key={i}>
                      // <Col className="col-md-4 mt-3" key={i}>
                        <div className='testimonial' key={i}>
                            <div className='profile-img'>
                              <img src={d.img}  alt='' />
                            </div>
                          <div className='gredient-border'>
                                <div className='gredientbox'>
                                  <h4 className='name'>{d.name}</h4>
                                  <h6 className='designation'>{d.designation}</h6>
                                  <p className='description'><ExpandableText  text={d.description}  maxLength={d.wc} /></p>
                                </div>
                            </div>

                        </div>
                      
                      // </Col>
                      ))}

                  </div>
             
                  {(full === 0) ? 
                    <div className='view-btn' onClick={() => setFull(1)}><img src={down} alt='' /></div> : 
                    <div className='view-btn' onClick={() => setFull(0)}><img style={{transform:'rotate(180deg)'}} src={down} alt='' /></div>
                  }                 
                </div>
                
              </div>

            </Col>
          </Row>
        </Container>
      </section>
      



      <section className='testimonial-section mobile' >
        <Container>
          <Row className='py-5'>
          <Col className='col-12'>
            <div className='content-box'>
              <div className="h3">Testimonial </div>
              <div className="strok">Testimonial</div>
              <div className="h1 anima"><span className='anima-underline' > Wall</span></div>
            </div>
            </Col>
          </Row>

          <Row className="mobileimg mx-auto">
            <Col className='col-md-12'>
              {/* {
                mobiledata1.map((d) => (
                    
                  <div key={d.id} className='gredient-border'>
                    <div className='gredientbox'>
                    <NavLink to='/solution' onClick={scrollToTop}>
                      <img src={d.img} className="" alt="..." />
                      <div className='overlay'></div>
                      <div className='overlay-text'>{d.title}</div>
                    </NavLink>  
                    </div>
                  </div>

                ))
              } */}

                    <div className='testimonials' data-masonry-options='{ "columnWidth": 280 }'>


                    {data.slice(0, limit).map((d, i) => (

                    // <Col className={`col-md-4 ${d.id % 2 === 0 ? "mt-5" : "mt-1"}`} key={i}>
                    // <Col className="col-md-4 mt-3" key={i}>
                      <div className='testimonial' key={i}>
                          <div className='profile-img'>
                            <img src={d.img}  alt='' />
                          </div>
                        <div className='gredient-border'>
                              <div className='gredientbox'>
                                <h4 className='name'>{d.name}</h4>
                                <h6 className='designation'>{d.designation}</h6>
                                <p className='description'>{d.description}</p>
                            </div>
                          </div>

                      </div>

                    // </Col>
                    ))}

                    </div>

            </Col>
          
          </Row>


         <Row>
            <div className='mobilebtn mb-5'>
            {(full === 0) ? 
                    <div className='view-btn' onClick={() => setFull(1)}><img src={down} alt='' /></div> : 
                    <div className='view-btn' onClick={() => setFull(0)}><img style={{transform:'rotate(180deg)'}} src={down} alt='' /></div>
                } 
            </div>
          </Row>
   
        </Container>

      </section>
    </>
  )
}


export default Testimonial