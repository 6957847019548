import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './ppbody.css'

const PPbody = () => {
  return (
    <section className='pp-section'>
    <Container>
        <Row className='px-3'>
            <Col>
              <p><strong>Privacy Policy</strong></p>
              <p>Last updated: 1, August 2023</p>
              <p><strong>Interpretation</strong></p>
              <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
              <p><strong>Definitions</strong></p>
              <p>For the purposes of this Privacy Policy:</p>
              <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where “control” means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
              <p><strong>Company</strong> (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Preferred Square Analytics Pvt. Ltd. , TOWER 4 1601, THE CLOSE NORTH NIRVANA, GURGAON, INDIA.</p>
              <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
              <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
              <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
              <p><strong>Service</strong> refers to the Website.</p>
              <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
              <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
              <p><strong>Website</strong> refers to Preferred Square , accessible from <a href="https://www.preferred-square.com/" target="_blank" rel="noreferrer noopener">https://www.preferred-square.com/</a></p>
              <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
              <p><strong>Introduction</strong></p>
              <p>Preferred Square Analytics Pvt. Ltd., (its holding entity and all affiliates and subsidiaries, both foreign and domestic, collectively, “Preferred Square,” or “we,” “us,” “our”) respect your concerns about personal data protection and value our relationship with you, (“user” or “you”, “your”).</p>
              <p>Preferred Square is committed to complying with the applicable data privacy and security requirements in the countries in which it operates.  Preferred Square complies with internationally recognized standards of privacy protection, and with various privacy laws globally including, but not limited to, the EU General Data Protection Regulation 2016/679 (GDPR), California Consumer Privacy Act AB-375 (CCPA) . This Policy applies when providing valuation, research, financial advisory, business development, data analytics, real estate advisory, or other services (the “Services”) to its clients or collection and use of personal information you may supply to us through your conduct on the Website.</p>
              <p><strong>Collecting Data</strong></p>
              <p>Data will be collected by Preferred Square collects personal data to offer and administer our Services and products. Preferred Square collects the following categories of personal data:</p>
              <ol start="1">
                <li><strong>Contact Data</strong>: We may collect information about data subjects such as name and contact details (email, phone number, etc.) in order to communicate and facilitate the provision of our Services with our clients or potential clients. For example, contact details of individuals who work for or on behalf of the clients, in order to carry out the client’s engagement with Preferred Square.</li>
              </ol>
              <ol start="2">
                <li><strong>Services Data</strong>: Personal data may be provided to us by clients to the extent required to perform the Services. Preferred Square may also acquire personal data from a third party at the direction of our client as required to perform the Services.</li>
              </ol>
              <ol start="3">
                <li><strong>Marketing Information</strong>: We may collect information to respond to inquiries regarding our products and Services or to provide you with information, reports, or updates.</li>
              </ol>
              <ol start="4">
                <li><strong>Website Visitor Information</strong>: When you visit our website www.preferred-square.com; “Website”), we may collect information about your visit such as your IP address and the pages you visited and when you use our services, we may collect information on how you use those services. An IP address is a unique number that is automatically assigned to your computer when connected to a network. Web servers automatically identify your computer by its IP address. When you visit the Website, our servers and content management system log your IP address. Please see our Terms of Use and Cookie Policy for additional information. Such information is typically considered non-personal information.</li>
              </ol>
              <p>We also collect the following:</p>
              <p><em>Cookies</em></p>
              <p>Our Website may use “Cookies” to identify the areas of our Website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We may use Cookies to personalize the content that you see on our Website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our Website correctly or at all.</p>
              <p><em>Log Information</em></p>
              <p>We may automatically receive information from your web browser or mobile device. This information includes the name of the website from which you entered our Website, if any, as well as the name of the website to which you’re headed when you leave our Website. This information also includes the IP address of your computer/proxy server that you use to access the internet, your internet website provider name, web browser type, type of mobile device, and computer operating system. We may use all this information to analyze trends among our users to help improve our Website.</p>
              <p>Clients and other third parties who provide personal information to Preferred Square must do so in compliance with applicable data privacy regulations.</p>
              <p><strong>Cookie Policy (“Cookie Policy”)</strong></p>
              <p>A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We may use Cookies to personalize the content that you see on our Website.</p>
              <p>Types of cookies Preferred Square uses include:</p>
              <p><em>Essential Cookies</em></p>
              <p>These cookies enable the Website to function. Certain functional cookies also allow us to respond to service or other inquiries received through a form.</p>
              <p><em>Functional Cookies</em></p>
              <p>These cookies enable the Website to function. Certain functional cookies also allow us to respond to service or other inquiries received through a form.</p>
              <p><em>Analytics Cookies</em></p>
              <p>Analytics cookies track aggregate Website performance, web speed, traffic sources, video plays and other aggregate data across the Website. These cookies allow us to personalize web experience by type of visitor and, upon certain circumstances, by individual user. Individual user information is recognized through form completions or response to certain email marketing campaigns.</p>
              <p><em>Advertising Cookies</em></p>
              <p>Upon occasion, Preferred Square may advertise on certain media sites. In order to track ad performance, we may place third party cookies from the ad partners. The ad partners may track anonymous site usage behavior on the Website and link it to other information they have associated with your IP address.</p>
              <p>In addition to cookies, some information may automatically be collected as you browse our Website, such as type of browser, operating system, domain name or IP address.</p>
              <p><strong>Processing of Personal Data</strong></p>
              <p>Preferred Square follows the following principles with respect to the processing of personal data:</p>
              <ol start="1">
                <li>personal data to be processed fairly and lawfully;</li>
              </ol>
              <ol start="2">
                <li>personal data to be collected for specified, explicit, and legitimate purposes and not further processed for incompatible purposes;</li>
              </ol>
              <ol start="3">
                <li>personal data collected by Preferred Square to be adequate, relevant, and not excessive in relation to the purposes for which it is collected;</li>
              </ol>
              <ol start="4">
                <li>personal data collected by Preferred Square to be accurate and, where necessary, kept up to date to the best of our ability, and</li>
              </ol>
              <ol start="5">
                <li>personal data collected by Preferred Square to be retained as identifiable data for no longer than necessary to serve the purposes for which the personal data was collected.</li>
              </ol>
              <p>If Preferred Square engages in the processing of personal data for purposes other than those specified in this Policy, Preferred Square will provide notice of these changes, the purposes for which the personal data will be used, and the recipients of personal data.</p>
              <p>The data you provide to us will be processed in accordance with the purposes specified in this Policy, namely:</p>
              <ol start="1">
                <li>To provide the products or perform the Services requested by clients and individuals pursuant to a letter of engagement, statement of work, or similar (where the processing is necessary for our legitimate business interests in conducting and managing our business);</li>
              </ol>
              <ol start="2">
                <li>To provide the products or perform the Services requested by clients and individuals using our Website or web applications (where the processing is necessary for our legitimate business interests in conducting and managing our business);</li>
              </ol>
              <ol start="3">
                <li>For complying with obligations provided by laws and current regulations;</li>
              </ol>
              <ol start="4">
                <li>For legitimate business purposes to advise you through e-mail, phone call, or post, in the framework of our ordinary relationship, about other products or services similar to the products or Services we have provided to you and that we think will be of interest to you (where the processing is necessary for our legitimate business interests)</li>
              </ol>
              <ol start="5">
                <li>For marketing purposes: For example, we may use your information to further discuss your interest in the Services and to send you information regarding Preferred Square such as information about promotions, events, products or Services;</li>
              </ol>
              <ol start="6">
                <li>You can manage your receipt of marketing and non-transactional communications by clicking on the <a href="http://javascript:;/" target="_blank" rel="noreferrer noopener">«unsubscribe»</a> link located on the bottom of Preferred Square marketing communications. Additionally, you may contact us on info@preferred-square.com</li>
              </ol>
              <ol start="7">
                <li>For improving Preferred Square’s communications with you: Emails sent to you by Preferred Square may include standard tracking, including open and click activities. Preferred Square may collect information about your activity as you interact with our email messages and related content.</li>
              </ol>
              <ol start="8">
                <li>For operating and improving our Website and your business experience: For example, we may collect and analyze data on your use of our Website and process it for the purpose of improving your online experience. Please see our Please see our Terms of Use and Cookie Policy for additional information.</li>
              </ol>
              <ol start="9">
                <li>For security purposes: For example, we may use your data to protect Preferred Square and its third parties against security breaches and to prevent fraud and violation of Preferred Square’s applicable agreements (where the processing is necessary for our legitimate business interests).</li>
              </ol>
              <p>Whenever we process your personal data for our legitimate interests, we make sure to consider and balance any potential impact on you and your rights under data protection laws. Our legitimate business interests do not automatically override your interests. We will not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You have the right to object to this processing, if you may prefer.</p>
              <p><strong>How data is processed</strong></p>
              <p>Personal data is processed both manually and electronically in accordance with the above-mentioned purposes and in compliance with current regulations. We permit only authorized Preferred Square employees and authorized representatives to have access to your information. Such employees and representatives are appropriately designated and trained to process data only in accordance with the instructions we provide them.</p>
              <p><strong>Accuracy</strong></p>
              <p>It is your responsibility to provide Preferred Square with accurate personal data. Except as otherwise set forth in this Policy, Preferred Square shall only use personal data in ways that are compatible with the purposes for which it was collected or subsequently authorized by you. To the extent necessary for these purposes, Preferred Square shall take reasonable steps to ensure that personal data is accurate, complete, current and relevant to its intended use.</p>
              <p><strong>Storage of Personal Data</strong></p>
              <p>Preferred Square will retain personal data for a reasonable period, taking into account legitimate business needs to capture and retain such information. Information will also be retained for a period necessary to comply with state, local, federal regulations, or country specific regulations and requirements, and in accordance with Preferred Square’s document retention policy.</p>
              <p><strong>Disclosure/Sharing of Personal Data</strong></p>
              <p>We only share your personal data with your consent or in accordance with this Policy. We will not otherwise share, sell or distribute any of the information you provide to us except as described in this Policy.</p>
              <p>We share personal data among Preferred Square entities who act for Preferred Square for the purposes set out in this Policy.</p>
              <p>Preferred Square may share your information with its representatives and other service providers who are performing certain services on behalf of Preferred Square. Such third parties have access to personal data solely for the purposes of performing the services specified in the applicable service contract, and not for any other purpose. Preferred Square requires these third parties to undertake security measures consistent with the protections specified in this Policy.</p>
              <p>Preferred Square may be required to disclose personal data in response to lawful requests by public authorities, including meeting national security or law enforcement requirements.</p>
              <p>If Preferred Square’s business enters into a joint venture with or is merged with another business entity, your information may be disclosed to our new business partners.</p>
              <p><strong>Cross-Border Transfers of Personal Data</strong></p>
              <p>Preferred Square is a global firm with operations in several countries. Personal information may be transferred, accessed and stored globally as necessary for the uses stated above in accordance with this Policy, and in compliance with local regulations.</p>
              <p>Personal Data may be transferred to or processed in locations outside of the European Economic Area (EEA), some of which have not been determined by the European Commission to have an adequate level of data protection. In that case, for personal data subject to European data protection laws, we take measures designed to provide the level of data protection required in the EU, including ensuring transfers governed by the requirements of the Standard Contractual Clauses adopted by the European Commission, or another adequate transfer mechanism.</p>
              <p>Where we receive requests to disclose personal data from law enforcement or regulators, we carefully validate these requests, including reviewing the legality of any order and challenging the order if there are grounds under the law to do so, before any personal data is disclosed.</p>
              <p><strong>Your Rights</strong></p>
              <p>Depending on the laws of the jurisdiction governing the processing of your personal data, you may have certain rights under applicable data protection laws including:</p>
              <ol start="1">
                <li><strong>Access</strong>: You have the right to access personal information that Preferred Square holds about you;</li>
              </ol>
              <ol start="2">
                <li><strong>Rectification</strong>: You have the right to ask us to rectify information Preferred Square holds about you if it is inaccurate or not complete;</li>
              </ol>
              <ol start="3">
                <li><strong>Erasure</strong>: You can request that Preferred Square erase your personal data. We will keep basic data to identify you and retain it solely for preventing further unwanted processing;</li>
              </ol>
              <ol start="4">
                <li><strong>Restrict Processing</strong>: You have the right to ask Preferred Square to restrict how we process your data. This means we are permitted to store the data but not further process it. We keep just enough data to make sure we respect your request in the future;</li>
              </ol>
              <ol start="5">
                <li><strong>Object to processing</strong>: Where processing is based on legitimate interests, you have the right to object to Preferred Square processing your data. Preferred Square will discontinue processing your data, unless we can demonstrate compelling legitimate grounds for the processing.  We will keep your basic data to identify you and retain it solely for preventing further unwanted processing;</li>
              </ol>
              <ol start="6">
                <li><strong>Portability</strong>: Where processing is based on consent or performance of a contract, you have the right to data portability. Preferred Square must allow you to obtain and reuse your personal data for your own purposes in a safe and secure way without this effecting the usability of your data. This right only applies to personal data that you have provided to Preferred Square as the data controller.</li>
              </ol>
              <p>Please write to us on info@preferred-square.com to request access, rectification, or erasure, or to restrict processing, to object to processing, to request data portability. Subject to legal considerations or certain exemptions, we may not always be able to address your request, for example, if it would impact the duty of confidentiality we owe to others, or if we are legally entitled to deal with the request in a different way. Please contact us by email to ensure your request is received in a timely manner.</p>
              <p><strong>Automated Decision Making</strong></p>
              <p>Automated decisions are defined as decisions about individuals that are based solely on the automated processing of data and that produce legal effects that significantly affect the individuals involved.</p>
              <p>Preferred Square does not make automated decisions using personal data. If automated decisions are to be made, affected persons will be given an opportunity to express their views on the automated decision in question and object to it.</p>
              <p><strong>Providing Information to Preferred Square</strong></p>
              <p>If you choose not to provide certain personal information, it may be an impediment to the exchange of information necessary for the execution of the contract or provision of Services, and we may not be able to provide you with some services and you may not be able to participate in some of the activities on our Website.</p>
              <p><strong>Third Party Websites or Other Services</strong></p>
              <p>We are not responsible for the privacy practices of any non-Preferred Square operated websites, mobile apps or other digital services, including those that may be linked through our Website or services, and we encourage you to review the privacy policies or notices published thereon.</p>
              <p><strong>Miscellaneous Privacy Issues</strong></p>
              <p>Preferred Square is committed to protecting the privacy of children. Our policy is that we do not knowingly collect, use or disclose personal data about visitors that we know are under 13 years of age. This Website is not directed in any respect to children under the age of 13.</p>
              <p>You have the right to complain to a Data Protection Authority about our collection and use of your personal information. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>
              <p><strong>Service Providers</strong></p>
              <p>We employ third party companies and individuals to facilitate our Website (“Service Providers”), to provide our Website on our behalf, to perform Website-related services or to assist us in analyzing how our Website is used. These third-parties may have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
              <p><strong>California Consumer Privacy Act (CCPA)</strong></p>
              <p>Preferred Square may collect and use consumer personal information for its business purposes and related operational purposes. With respect to these activities, Preferred Square may have direct responsibilities as a business subject to the CCPA. For example, for certain activities related to interacting with prospective/current clients, marketing, website and web application use, engaging vendors, and interacting with visitors/users of our Website. In these cases, this Policy is applicable.</p>
              <p>Preferred Square also collects information in its capacity as a service provider to our clients, providing Services. In that case, Preferred Square receives personal information from our clients where we have no direct relationship with the individuals whose personal information we process, and information is provided to us solely for the business purpose of providing those Services. If you are a customer or other user associated with one of our clients on whose behalf we have collected or processed your information, this Policy does not apply to you, and if you have questions or wish to exercise your rights relating to your personal information (such as information, access or deletion), please contact that client directly.</p>
              <p><em>Information We Collect</em></p>
              <p>Preferred Square collects personal data in the course of its business activities, including in connection with some client services. We also collect personal data on certain areas of the Sites when you register for or apply for jobs through the Preferred Square Recruitment Portal, register for Preferred Square newsletters and alerts, sign up for conferences and events, and/or participate in public user posting areas (such as bulletin boards, discussion forums and surveys). The personal data collected varies and may include information that you provide, such as your name, mailing address, e-mail address, telephone number, fax number, and background information required to apply for a job.</p>
              <p>We may also automatically collect information about the devices you use to interact with our Sites. The information we automatically collect may include IP address, device identifier, web browser, and browsing information collected through cookies, web beacons, pixels, clear gifs, and other similar technologies (collectively “Cookies and Other Tracking Technologies”) on our Sites. We may also automatically collect information about how you use the Sites, such as what you have searched for and viewed on the Sites. The information automatically collected may be associated with a unique identifier as well as with any other personal data you have provided.</p>
              <p>Preferred Square may also collect personal data about you from third parties, such as data brokers or aggregators, in the course of its business activities including in connection with some client services. This includes demographic, professional and other information that is publicly available online, including information you choose to make public through social media platforms and other public online forums. We will always seek to confirm that the third party has provided transparent information about its use of this data, including its disclosure to third parties like Preferred Square, in compliance with applicable law.</p>
              <p>Personal information does not include:</p>
              <ol start="1">
                <li>Publicly available information from government records;</li>
              </ol>
              <ol start="2">
                <li>De-identified or aggregated consumer information;</li>
              </ol>
              <ol start="3">
                <li>Information excluded from the CCPA’s scope, like:</li>
              </ol>
              <ol start="1">
                <li>Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</li>
              </ol>
              <ol start="2">
                <li>personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FCRA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (CalFIPA), and the Driver’s Privacy Protection Act of 1994.</li>
              </ol>
              <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
              <ol start="1">
                <li>Directly from our clients or their agents. For example, from documents that our clients provide to us related to the Services for which they engage us;</li>
              </ol>
              <ol start="2">
                <li>Indirectly from our clients or their agents. For example, through information we collect from our clients in the course of providing Services to them;</li>
              </ol>
              <ol start="3">
                <li>Directly and indirectly from activity on our Website. For example, from submissions through our Website portal or Website usage details collected automatically;</li>
              </ol>
              <ol start="4">
                <li>From third-parties that interact with us in connection with the Services we perform.</li>
              </ol>
              <p><em>Use of Personal Information</em></p>
              <p>We may use or disclose the personal information we collect for one or more of the following business purposes:</p>
              <ol start="1">
                <li>To fulfill or meet the reason for which the information is provided;</li>
              </ol>
              <ol start="2">
                <li>To provide you with information, products or services that you request from us;</li>
              </ol>
              <ol start="3">
                <li>To provide you with email alerts, event registrations and other notices concerning our products or services, or events or news, that may be of interest to you;</li>
              </ol>
              <ol start="4">
                <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us;</li>
              </ol>
              <ol start="5">
                <li>To improve our Website and present its contents to you;</li>
              </ol>
              <ol start="6">
                <li>For testing, research, analysis and product development;</li>
              </ol>
              <ol start="7">
                <li>As necessary or appropriate to protect the rights, property or safety of us, our clients or others;</li>
              </ol>
              <ol start="8">
                <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations;</li>
              </ol>
              <ol start="9">
                <li>As described to you when collecting your personal information or as otherwise set forth in the CCPA;</li>
              </ol>
              <ol start="10">
                <li>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us is among the assets transferred.</li>
              </ol>
              <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
              <p><em>Sharing Personal Information</em></p>
              <p>We may disclose your personal information to a third party for a business purpose.  When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.</p>
              <p>In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:</p>
              <p>Category A:             Identifiers</p>
              <p>Category B:             California Customer Records personal information categories</p>
              <p>Category C:             Protected classification characteristics under California or federal law</p>
              <p>Category I:              Professional or employment-related information</p>
              <p>We disclose your personal information for a business purpose to the following categories of third parties:</p>
              <ol start="1">
                <li>Our affiliates;</li>
              </ol>
              <ol start="2">
                <li>Service providers;</li>
              </ol>
              <ol start="3">
                <li>Third parties to whom you or your representatives authorize us to disclose your personal information in connection with products or services we provide to you.</li>
              </ol>
              <p>IN THE PRECEDING TWELVE (12) MONTHS, WE HAVE NOT SOLD ANY PERSONAL INFORMATION.</p>
              <p><em>Your Rights and Choices</em></p>
              <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>
              <p><em>Access to Specific Information and Data Portability Rights</em></p>
              <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
              <ol start="1">
                <li>The categories of personal information we collected about you;</li>
              </ol>
              <ol start="2">
                <li>The categories of sources for the personal information we collected about you;</li>
              </ol>
              <ol start="3">
                <li>Our business or commercial purpose for collecting or selling that personal information;</li>
              </ol>
              <ol start="4">
                <li>The categories of third parties with whom we share that personal information;</li>
              </ol>
              <ol start="5">
                <li>The specific pieces of personal information we collected about you (also called a data portability request);</li>
              </ol>
              <ol start="6">
                <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</li>
              </ol>
              <ol start="1">
                <li>sales, identifying the personal information categories that each category of recipient purchased; and</li>
              </ol>
              <ol start="2">
                <li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
              </ol>
              <p><em>Deletion Request Rights</em></p>
              <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
              <p>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</p>
              <ol start="1">
                <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you;</li>
              </ol>
              <ol start="2">
                <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities;</li>
              </ol>
              <ol start="3">
                <li>Debug products to identify and repair errors that impair existing intended functionality;</li>
              </ol>
              <ol start="4">
                <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law;</li>
              </ol>
              <ol start="5">
                <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.);</li>
              </ol>
              <ol start="6">
                <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent;</li>
              </ol>
              <ol start="7">
                <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us;</li>
              </ol>
              <ol start="8">
                <li>Comply with a legal obligation;</li>
              </ol>
              <ol start="9">
                <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
              </ol>
              <p><em>Exercising Access, Data Portability, and Deletion Rights</em></p>
              <p>To exercise the access, data portability, and deletion rights described above, please contact us on <a href="mailto:info@preferred-square.com">info@preferred-square.com</a></p>
              <p>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.</p>
              <p>You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
              <ol start="1">
                <li>provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative;</li>
              </ol>
              <ol start="2">
                <li>describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
              </ol>
              <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require you to create an account with us. We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.</p>
              <p><em>Response Timing and Format</em></p>
              <p>We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. We will deliver our written response electronically. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
              <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
              <p><em>Non-Discrimination</em></p>
              <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
              <ol start="1">
                <li>deny you goods or services;</li>
              </ol>
              <ol start="2">
                <li>charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties;</li>
              </ol>
              <ol start="3">
                <li>provide you a different level or quality of goods or services;</li>
              </ol>
              <ol start="4">
                <li>suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
              </ol>
              <p><strong>Compliance</strong></p>
              <p>Preferred Square will use a self-assessment approach to verify compliance with this Policy and periodically verify that the Policy is accurate, comprehensive for the information intended to be covered, prominently displayed, implemented and accessible.</p>
              <p>If you believe that your personal data/personal information has been processed or disclosed in violation of this Policy, Preferred Square encourages you to raise any concerns using the contact information provided in this Policy. Preferred Square will investigate and attempt to resolve any complaints and disputes regarding use and disclosure of your personal data/personal information.</p>
              <p><strong>Changes to this Policy</strong></p>
              <p>This Policy may be amended from time to time, consistent with the requirements of applicable law. A notice will be posted by Preferred Square on this Website when this Policy is changed.</p>
              <p><strong>Contact Us</strong></p>
              <p>Please contact us on <a href="mailto:info@preferred-square.com">info@preferred-square.com</a> with questions, concerns, or complaints.</p>
            </Col>
        </Row> 
    </Container> 
    </section>      

  )
}

export default PPbody
