import React from 'react'
import Menu from '../component/Menu';
import Headercontact from '../component/Headercontact'
import Footer from '../component/Footer'
import Contactbody from '../component/Contactbody'
import { Helmet } from 'react-helmet';

const Contact = ({setBlank}) => {

  return (
    <>
    <Helmet>
      <title>Contact Us</title>
      <meta property="og:title" content="Contact Us" />
      <meta property='og:description' content='Contact Us'/>
      <meta name='keywords' content=''/>
    </Helmet>
        <Menu setBlank={setBlank}  />
        <Headercontact />
        <Contactbody />
        <Footer  setBlank={setBlank}  />
      
    </>
  )
}

export default Contact
