import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
// import { gsap } from "gsap";
import { NavLink } from 'react-router-dom';

import './solutionbox.css'



const SolutionBox = ({setBlank}) => {
 
  const scrollToTop = () => {
    setBlank(true);
    setTimeout(() => {
    window.scrollTo(0, 0);
    }, 100);
    setTimeout(() => {
    setBlank(false);            
    }, 600);
}

  const handleSubmit = (values) => {
    console.log(values);
  };


   const [mbox1, setMbox1] = useState(false);
   const [mbox2, setMbox2] = useState(false);
   const [mbox3, setMbox3] = useState(false);
   const [mbox4, setMbox4] = useState(false);
   const [mbox5, setMbox5] = useState(false);
   const [mbox6, setMbox6] = useState(false);
   const [mbox7, setMbox7] = useState(false);
   const [mbox8, setMbox8] = useState(false);

const closeAll = ()=> {
  setMbox1(false);
  setMbox2(false);
  setMbox3(false);
  setMbox4(false);
  setMbox5(false);
  setMbox6(false);
  setMbox7(false);
  setMbox8(false);
}


  useEffect(() => {


    // let text = document.querySelector(".hover-box");

    // let animation = gsap.to(text, {
    //     toggleClass:"hover"
    // });

    // text.addEventListener("mouseenter", () => animation.play());
    // text.addEventListener("mouseleave", () => animation.reverse());

    // gsap.to(".wherewdbg", {
    //     left: 0,
    //     duration: 5,
    //     delay: 2,
    //     scrollTrigger: {
    //         ease: "power1.out",
    //         trigger: ".wherewdbg",
    //     // markers: true,
    //     start: "top bottom",
    //     end: "bottom bottom",
    //     scrub: 5
    //     }
    // });

  }, [])

  return (
    <section className='solutionbox-section w-100' >
      <Container fluid className='p-0 m-0 w-100'>
        <Row className='p-0 m-0 g-0'>
          {/* hover box 1*/}
          <Col className='col-md-6 hover-box hover'>
            <div className='sol-box white box1'>
              <Row className='justify-content-end'>
                <Col className='col-md-6 s-box'>
                  <div className='text-box'>
                    <h4>Real Estate</h4>
                    <p>We enable organizations across the real estate spectrum, including master developers, real estate-focused private equity funds, and asset managers to make research and data-driven decisions on strategy, business planning, investment and development. </p>
                    
                    <p className= {mbox1 === true ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{closeAll(); setMbox1(true)}}>See More</p>
                  </div>

                </Col>

                
                <Col className= {mbox1 === true ? "col-md-6 e-box mb-block" : "col-md-6 e-box"} >
                  <div className='box-list'>

                    <ul className='list'>
                      <li>Highest and best use (HBU) studies 	</li>
                      <li>Real estate valuation	</li>
                      <li>Strategic management office (SMO)	</li>
                      <li>Feasibility studies 	</li>
                      <li>Market and industry research 		</li>
                      <li>Land use planning		</li>
                      <li>Portfolio analysis & monitoring		</li>
                    </ul>
                    <p className= {mbox1 === false ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ setMbox1(false)}}>Less </p>

                    <div className="form-group pt-1 pb-5 text-center flex items-center justify-between">
                      <NavLink to="/contact" onClick={scrollToTop}> <button className="applynow-btn" type="submit" onClick={handleSubmit}>Talk to our experts now</button></NavLink>
                    </div>

                  </div>
                </Col>
              </Row>
             
            </div>
          </Col>

          {/* hover box 2*/}
          <Col className='col-md-6 hover-box hover'>
            <div className='sol-box white box8'>
              <Row className='justify-content-end'>
                <Col className='col-md-6 s-box'>
                  <div className='text-box'>
                    <h4>Family Offices</h4>
                    <p>We understand that each family office is unique in its foundation, challenges, and goals. Be it a single-family office (SFO), multi-family office (MFO) or virtual family office (VFO), we customize our solutions to meet the goals, requirements and preferences of our clients. We act as the strategy management office (SMO) and provide support on both - direct and fund investments.</p>
                    <p className= {mbox8 === true ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ closeAll(); setMbox8(true)}}>See More</p>
                      </div>

                </Col>
                <Col className= {mbox8 === true ? "col-md-6 e-box mb-block" : "col-md-6 e-box"} >
                  <div className='box-list'>

                    <ul className='list'>
                      <li>Portfolio analysis & monitoring	</li>
                      <li>Corporate and business strategy	</li>
                      <li>Investment strategy	</li>
                      <li>Transaction support		</li>
                      <li>Project management office (PMO)</li>
                      <li>Strategic management office (SMO)		</li>
                    </ul>
                    <p className= {mbox8 === false ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ setMbox8(false)}}>Less</p>

                    <div className="form-group pt-1 pb-5 text-center flex items-center justify-between">
                    <NavLink to="/contact" onClick={scrollToTop}> <button className="applynow-btn" type="submit" onClick={handleSubmit}>Talk to our experts now</button></NavLink>
                    </div>

                  </div>
                </Col>
              </Row>

            </div>
          </Col>

          {/* hover box 3*/}
          <Col className='col-md-6 hover-box hover'>
            <div className='sol-box white box2'>
              <Row className='justify-content-end'>
                <Col className='col-md-6 s-box'>
                  <div className='text-box'>
                    <h4>Investment banks & advisors</h4>
                    <p>With expertize in both - the buy and sell side - we work with bankers and advisors as their extended arm to create innovative strategies and solutions that enable them to achieve their financial objectives. </p>
                    <p className= {mbox2 === true ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ closeAll(); setMbox2(true)}}>See More</p>
                  </div>

                </Col>
                <Col className= {mbox2 === true ? "col-md-6 e-box mb-block" : "col-md-6 e-box"} >

                  <div className='box-list'>

                    <ul className='list'>
                      <li>M&A Support	</li>
                      <li>Capital structure and analysis 		</li>
                      <li>Investment hypothesis testing	</li>
                      <li>Pitchdecks and PPM	</li>
                      <li>Exit strategy planning 	</li>
                      <li>Investor Kits		</li>
                      <li>Financial Modelling and Valuation</li>
                      <li>Trading and Transaction Comparables </li>
                      <li>Market and Industry Research 		</li>
                      <li>Term Sheet Evaluation		</li>
                      <li>Data Room Management		</li>
                      <li>Deal execution	</li>
                    </ul>

                    <p className= {mbox2 === false ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ setMbox2(false)}}>Less</p>

                    <div className="form-group pt-1 pb-5 text-center flex items-center justify-between">
                      <NavLink to="/contact" onClick={scrollToTop}> <button className="applynow-btn" type="submit" onClick={handleSubmit}>Talk to our experts now</button></NavLink>
                    </div>

                  </div>
                </Col>
              </Row>

            </div>
          </Col>

          {/* hover box 4*/}
          <Col className='col-md-6 hover-box hover'>
            <div className='sol-box white box5'>
              <Row className='justify-content-end'>
                <Col className='col-md-6 s-box'>
                  <div className='text-box'>
                    <h4>Private equity & venture capital</h4>
                    <p>
                    We assist private equity and venture capital firms with the flexibility to operate in a highly volatile environment by providing end-to-end support on the entire deal cycle - sourcing, due diligence, and execution.</p>
                      <p className= {mbox5 === true ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ closeAll(); setMbox5(true)}}>See More</p>
                    </div>

                </Col>
                <Col className= {mbox5 === true ? "col-md-6 e-box mb-block" : "col-md-6 e-box"} >

                  <div className='box-list'>

                    <ul className='list'>
                      <li>Financial modelling and valuation	</li>
                      <li>Transaction support (IMs, models, teasers)	</li>
                      <li>Industry landscaping	</li>
                      <li>Due diligence support		</li>
                      <li>Portfolio analysis & monitoring		</li>
                      <li>Investor Relations Support		</li>
                    </ul>

                    <p className= {mbox5 === false ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ setMbox5(false)}}>Less</p>

                    <div className="form-group pt-1 pb-5 text-center flex items-center justify-between">
                    <NavLink to="/contact" onClick={scrollToTop}><button className="applynow-btn" type="submit" onClick={handleSubmit}>Talk to our experts now</button></NavLink>
                    </div>

                  </div>
                </Col>
              </Row>

            </div>
          </Col>


        {/* hover box 5*/}
        <Col className='col-md-6 hover-box hover'>
            <div className='sol-box white box4'>
              <Row className='justify-content-end'>
                <Col className='col-md-6 s-box'>
                  <div className='text-box'>
                    <h4>Hedge Funds</h4>
                    <p>From identifying optimal investment vehicles to managing risks, our consultants work in tandem with the in-house team to orchestrate effective strategies that are attuned to the highly dynamic investment landscape.</p>
                    <p className= {mbox4 === true ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ closeAll(); setMbox4(true)}}>See More</p>
                   </div>

                </Col>
                <Col className= {mbox4 === true ? "col-md-6 e-box mb-block" : "col-md-6 e-box"} >

                  <div className='box-list'>

                    <ul className='list'>
                      <li>Market & industry research	</li>
                      <li>Portfolio analysis and monitoring	</li>
                      <li>Financial modelling and valuation	</li>
                      <li>Equity research 		</li>
                      <li>Buy side thematic notes		</li>
                      <li>Alternative investment analysis		</li>
                    </ul>

                    <p className= {mbox4 === false ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ setMbox4(false)}}>Less</p>

                    <div className="form-group pt-1 pb-5 text-center flex items-center justify-between">
                      <NavLink to="/contact" onClick={scrollToTop}><button className="applynow-btn" type="submit" onClick={handleSubmit}>Talk to our experts now</button></NavLink>
                    </div>

                  </div>
                </Col>
              </Row>

            </div>
          </Col>

  

          {/* hover box 3*/}
          <Col className='col-md-6 hover-box hover'>
            <div className='sol-box white box3'>
              <Row className='justify-content-end'>
                <Col className='col-md-6 s-box'>
                  <div className='text-box'>
                    <h4>Sovereign wealth funds & Endowment Funds</h4>
                    <p>We empower Sovereign Wealth and Endowment Funds with customized solutions, aligning risk management with long-term value creation in a dynamic landscape.</p>
                    <p className= {mbox3 === true ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ closeAll(); setMbox3(true)}}>See More</p>
                     </div>

                </Col>
                <Col className= {mbox3 === true ? "col-md-6 e-box mb-block" : "col-md-6 e-box"} >

                  <div className='box-list'>

                    <ul className='list'>
                      <li>Portfolio analysis and monitoring	</li>
                      <li>Business plans 	</li>
                      <li>Enterprise project management Office (EPMO)	</li>
                      <li>Investment policy development 		</li> 
                      <li>Board presentations 		</li>
                      <li>Manager selection & monitoring		</li>
                    </ul>

                    <p className= {mbox3 === false ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ setMbox3(false)}}>Less</p>

                    <div className="form-group pt-1 pb-5 text-center flex items-center justify-between">
                      <NavLink to="/contact" onClick={scrollToTop}> <button className="applynow-btn" type="submit" onClick={handleSubmit}>Talk to our experts now</button></NavLink>
                    </div>

                  </div>
                </Col>
              </Row>

            </div>
          </Col>

          {/* hover box 6*/}
          <Col className='col-md-6 hover-box hover'>
            <div className='sol-box black box6'>
              <Row className='justify-content-end'>
                <Col className='col-md-6 s-box'>
                  <div className='text-box'>
                    <h4>Asset managers</h4>
                    <p>We offer tailored solutions to our clients that enable them to optimize portfolio performance, streamline operations and drive investor satisfaction.</p>
                    <p className= {mbox6 === true ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ closeAll(); setMbox6(true)}}>See More</p>
                    </div>

                </Col>
                <Col className= {mbox6 === true ? "col-md-6 e-box mb-block" : "col-md-6 e-box"} >

                  <div className='box-list'>

                    <ul className='list'>
                      <li>Market & Industry Research	</li>
                      <li>Asset valuation	</li>
                      <li>Fund Tracking and Pricing 	</li>
                      <li>Portfolio analysis & monitoring		</li>
                      <li>Manager screening		</li>
                      <li>Financial modelling and valuation		</li>
                    </ul>

                    <p className= {mbox6 === false ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ setMbox6(false)}}>Less</p>

                    <div className="form-group pt-1 pb-5 text-center flex items-center justify-between">
                    <NavLink to="/contact" onClick={scrollToTop}><button className="applynow-btn" type="submit" onClick={handleSubmit}>Talk to our experts now</button></NavLink>
                    </div>

                  </div>
                </Col>
              </Row>

            </div>
          </Col>

          {/* hover box 7*/}
          <Col className='col-md-6 hover-box hover'>
            <div className='sol-box black box7'>
              <Row className='justify-content-end'>
                <Col className='col-md-6 s-box'>
                  <div className='text-box'>
                    <h4>Corporates</h4>
                    <p>We fuel corporate growth with bespoke strategies, balancing risk mitigation and sustainable profitability in a fast-paced business environment.</p>
                    <p className= {mbox7 === true ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ closeAll(); setMbox7(true)}}>See More</p>
                    </div>

                </Col>
                <Col className= {mbox7 === true ? "col-md-6 e-box mb-block" : "col-md-6 e-box"} >

                  <div className='box-list'>

                    <ul className='list'>
                      <li>Business plans 	</li>
                      <li>Annual operating plans	</li>
                      <li>Performance management	</li>
                      <li>Financial planning and analysis 		</li>
                      <li>Board presentations 		</li>
                      <li>Strategy Implementation		</li>
                    </ul>

                    <p className= {mbox7 === false ? "seemore mobile d-none" : "seemore mobile"} onClick={()=>{ setMbox7(false)}}>Less</p>

                    <div className="form-group pt-1 pb-5 text-center flex items-center justify-between">
                    <NavLink to="/contact" onClick={scrollToTop}><button className="applynow-btn" type="submit" onClick={handleSubmit}>Talk to our experts now</button></NavLink>
                    </div>

                  </div>
                </Col>
              </Row>

            </div>
          </Col>


        </Row>
      </Container>
    </section>
  )
}

export default SolutionBox