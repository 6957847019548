import React from 'react'
import JobOpeningheader from '../component/Jobopeningheader'
import Menu from '../component/Menu'
//import Getintouch2 from '../component/Getintouch2'
import Footer from '../component/Footer'
import Jobopeningbody from '../component/Jobopeningbody'
import { Helmet } from 'react-helmet';

const Jobopening = ({setBlank}) => {
  <Helmet>
    <title>Business Analyst</title>
    <meta property="og:title" content="Business Analyst" />
    <meta name='og:description' content='Business Analyst'/>
    <meta name='keywords' content=''/>
  </Helmet>

  return (
    <>
        <Menu setBlank={setBlank} />
        <JobOpeningheader/>
        <Jobopeningbody/>
        <Footer  setBlank={setBlank} />

    </>
  )
}

export default Jobopening