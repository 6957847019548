import * as Yup from "yup";

// const phoneRegExp = '^([+0-9]{1,3}|[-0-9]{1,3})?[0-9]{10}$';
export const signUpSchema = Yup.object({
  name: Yup.string().min(2).max(50).required("Please enter your name").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  company: Yup.string().min(2).max(50).required("Please enter your Company"),
  subject: Yup.string().min(2).max(25).required("Please enter your Subject"),
  country: Yup.string().required("Please select your Country"),
  email: Yup.string().email().min(2).required("Please enter your Email").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email must be a valid email "),
  // phone: Yup.number().required("Please enter your Phone Number"),
  // phone: Yup.string().min(10).max(13).required("Please enter your Phone Number").matches(phoneRegExp, 'Phone number is not valid'),
  message: Yup.string().min(4).required("Please enter Message"),
  agree: Yup.bool().oneOf([true], 'You must accept the terms and conditions') 
});