import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../component/Menu'
import HeaderCasestudy from '../component/HeaderCasestudy'
import Casestudybody13 from '../component/Casestudybody13'
import Footer from '../component/Footer'
import thumbimg from '../images/case-study-client9.webp'
    

const CaseStudy13 = ({setBlank}) => {
  
    // const headings = `<div class='desktop'><h2 class='desktop' style='display:inline-block'>Equity Valuation & Fairness </h2><br /><h2 style='display:inline-block;'>Opinion</h2><h4 style='display:inline-block;padding-left:15px;'> Exercise for a US-Based Hedge Fund</h4></div>
    // <div class='mobile tablet' ><h2 style='display:inline-block'>Equity Valuation & Fairness Opinion</h2><h4 style='display:inline-block;'> Exercise for a US-Based Hedge Fund</h4></div>`;

    const headings = `<h2 style='display:inline-block'>Equity Valuation & Fairness Opinion</h2><h4> Exercise for a US-Based Hedge Fund</h4>`;

  return (
    <>
    <Helmet>
      <title>Equity Valuation & Fairness Opinion Exercise For A US-Based Hedge Fund</title>
      <meta property="og:image" content={thumbimg} /> 
      <meta property="og:title" content="Equity Valuation & Fairness Opinion Exercise For A US-Based Hedge Fund" />
      <meta property="og:description" content="Equity Valuation & Fairness Opinion Exercise For A US-Based Hedge Fund"/>
    </Helmet>

    <Menu setBlank={setBlank} />
    <HeaderCasestudy headings={headings} class="header-casestudy13" />  
    <Casestudybody13 />
    <Footer setBlank={setBlank} />
</>

  )
}

export default CaseStudy13
