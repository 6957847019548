import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../component/Menu'
import HeaderCasestudy from '../component/HeaderCasestudy'
import Casestudybody9 from '../component/Casestudybody9'
import Footer from '../component/Footer'
import thumbimg from '../images/case-study-client9.webp'
    

const CaseStudy9 = ({setBlank}) => {
  
    const headings = " <h2 style='display:inline-block'>Industry Research </h2><h4>for an HNI Launching a New Coffee Roastery Brand in the U.S.</h4>";

  return (
    <>
    <Helmet>
      <title>Industry Research for an HNI Launching a New Coffee Roastery Brand in the U.S.</title>
      <meta property="og:image" content={thumbimg} /> 
      <meta property="og:title" content="Industry Research for an HNI Launching a New Coffee Roastery Brand in the U.S." />
      <meta property="og:description" content="Industry Research for an HNI Launching a New Coffee Roastery Brand in the U.S."/>
    </Helmet>

    <Menu setBlank={setBlank} />
    <HeaderCasestudy headings={headings} class="header-casestudy9" />  
    <Casestudybody9 />
    <Footer setBlank={setBlank} />
</>

  )
}

export default CaseStudy9
