import React from 'react'
import Menu from '../component/Menu';
import HeaderService from '../component/HeaderService'
import Getintouch2 from '../component/Getintouch2'
import Servicebody from '../component/Servicebody'
import Footer from '../component/Footer' 
import { Helmet } from 'react-helmet';


const Service = ({setBlank}) => {
  return (
    <>
    <Helmet>
      <title>Services - Preferred Square</title>
      <meta property="og:title" content="Services" />
      <meta name='og:description' content='Services'/>
      <meta name='keywords' content=''/>
    </Helmet>
      <Menu setBlank={setBlank} />
      <HeaderService />
      <Servicebody setBlank={setBlank} />
      <Getintouch2 setBlank={setBlank} />
      <Footer setBlank={setBlank} />
    </>
  )
}

export default Service