import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './casestudybody22.css';

import clientimg from '../images/case-study-client22.webp'
import outcome from '../images/outcome22.webp'

import sm1 from '../images/cs22so1.webp'
import sm2 from '../images/cs22so2.webp'


const Casestudybody22 = () => {

    return (
        <>
          <section className='casestudybody22-section'  >
          <Container>

            <Row className='w-100 py-md-5 py-4 clintrow'>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <h2>About</h2>
                      {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                      <div className="h1">The Client</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>The client is an investment corporation managing an AUM of USD 750 Mn, specializing in diverse asset classes including public and private equity, fixed income products, etc.</p>
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={clientimg} alt="" />
                </div>
            </Row>
       
            <Row className='mt-md-5'>
              <div className='col-md-12'>
                <div className='content-box'>
                  <h2>Situation</h2>
                  {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                  <div className="h1">Overview</div>
                  <p className='anima '><span className='only-anima-underline' > **** </span></p>
                  {/* <p>The client was facing challenges in managing & summarizing the organizational data. The data with the client was spread in silos and additionally, the information collection and aggregation required significant manual intervention and was a bit of a tedious process. To address this, the client contacted PS to provide support in establishing the data management process and developed customized dashboards to facilitate information access across all departments.</p> */}
                  {/* <p>The client was facing challenges in managing & summarizing the organizational data. The data with the client was spread in silos and additionally, the information collection and aggregation required significant manual intervention and was a bit tedious process. To address this, the client contacted Preferred Square to provide support in establishing the data management process and developed customized dashboards to facilitate information access across all departments.</p> */}
                  <p>The client was facing challenges in managing & summarizing the organizational data. The data with the client was spread in silos and additionally, the information collection and aggregation required significant manual intervention. To address this, the client contacted Preferred Square to provide support in establishing the data management process and developed customized dashboards to facilitate information access across all departments.</p>
                </div>
                
              </div>
            </Row>
          </Container>
        </section>
    
        <section className='casestudybody22-section bg-gredient-dark'>
        <Container>
            <Row>
              <div className='col-md-12'>
                  <div className='content-box'>
                    <h2>Solution and Approach:</h2>
                    {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                    <div className="h1">The 7-Staged methodology</div>
                    <p className='anima '><span className='only-anima-underline' > **** </span></p>
                    <p>Our 7-staged methodology effectively addressed the challenge of extracting and accessing meaningful insights from organizational data. The approach involved thoroughly understanding the client's requirements, developing a tailored dashboard, and provide post-implementation support.</p>
                    
                    <div className='method-stage'> <span>PHASE 01 </span>PLANNING</div>
                
                    <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                            <path d="M27.3525 36H23.0752V21.3516H18.0801V18.5098C19.3984 18.4512 20.3213 18.3633 20.8486 18.2461C21.6885 18.0605 22.3721 17.6895 22.8994 17.1328C23.2607 16.752 23.5342 16.2441 23.7197 15.6094C23.8271 15.2285 23.8809 14.9453 23.8809 14.7598H27.3525V36Z" fill="white"/>
                        </svg>


                        </div>
                        <div className='text'>
                          <h4>Define Scope of Work</h4>
                          <p>The first step was to clearly define the work scope, aligning expectations, and establish delivery timelines. We arranged workshops with the stakeholders to:</p>
                          <ul className='circle-list'>
                            <li>Gain a deep understanding of project objectives and requirements.</li>
                            <li>Identify the target audience/users and dashboard purpose.</li>
                            <li>Clarify expected outcomes and deliverables.</li>
                            <li>Create a detailed project timeline with tangible milestones.</li>
                          </ul>
                        </div>
                      </div>
    
                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                          <path d="M26.4297 19.0811C25.9316 18.4854 25.2188 18.1875 24.291 18.1875C23.0215 18.1875 22.1572 18.6611 21.6982 19.6084C21.4346 20.1553 21.2783 21.0244 21.2295 22.2158H17.1719C17.2402 20.4092 17.5674 18.9492 18.1533 17.8359C19.2666 15.7168 21.2441 14.6572 24.0859 14.6572C26.332 14.6572 28.1191 15.2822 29.4473 16.5322C30.7754 17.7725 31.4395 19.418 31.4395 21.4688C31.4395 23.041 30.9707 24.4375 30.0332 25.6582C29.418 26.4688 28.4072 27.3721 27.001 28.3682L25.3311 29.5547C24.2861 30.2969 23.5684 30.834 23.1777 31.166C22.7969 31.498 22.4746 31.8838 22.2109 32.3232H31.4834V36H16.9375C16.9766 34.4766 17.3037 33.085 17.9189 31.8252C18.5146 30.4092 19.9209 28.9102 22.1377 27.3281C24.0615 25.9512 25.3066 24.9648 25.873 24.3691C26.7422 23.4414 27.1768 22.4258 27.1768 21.3223C27.1768 20.4238 26.9277 19.6768 26.4297 19.0811Z" fill="white"/>
                        </svg>

                        </div>
                        <div className='text'>
                          <h4>Data Source Identification</h4>
                          <p>We initiated a data exploration process to establish a foundation for the dashboard. This involved:</p>
                          <ul className='circle-list'>
                            <li>Identifying and defining the required data sources for dashboard development.</li>
                            <li>Determining access methods for data stored in files, databases, or systems.</li>
                            <li>Set up data access by obtaining required permissions from the client.</li>
                            <li>Conducting in-depth analysis to determine data quality and usability.</li>
                            <li>Developing a conceptual framework for the data model.</li>
                          </ul>
                          <p>These steps collectively enabled us to create a data dictionary to guide the dashboard's development.</p>
                        </div>
                      </div>

                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                          <path d="M20.8926 28.4814C20.8926 29.3311 21.0293 30.0342 21.3027 30.5908C21.8105 31.6162 22.7334 32.1289 24.0713 32.1289C24.8916 32.1289 25.6045 31.8506 26.21 31.2939C26.8252 30.7275 27.1328 29.917 27.1328 28.8623C27.1328 27.4658 26.5664 26.5332 25.4336 26.0645C24.7891 25.8008 23.7734 25.6689 22.3867 25.6689V22.6807C23.7441 22.6611 24.6914 22.5293 25.2285 22.2852C26.1562 21.875 26.6201 21.0449 26.6201 19.7949C26.6201 18.9844 26.3809 18.3252 25.9023 17.8174C25.4336 17.3096 24.7695 17.0557 23.9102 17.0557C22.9238 17.0557 22.1963 17.3682 21.7275 17.9932C21.2686 18.6182 21.0488 19.4531 21.0684 20.498H17.1719C17.2109 19.4434 17.3916 18.4424 17.7139 17.4951C18.0557 16.665 18.5928 15.8984 19.3252 15.1953C19.8721 14.6973 20.5215 14.3164 21.2734 14.0527C22.0254 13.7891 22.9482 13.6572 24.042 13.6572C26.0732 13.6572 27.709 14.1846 28.9492 15.2393C30.1992 16.2842 30.8242 17.6904 30.8242 19.458C30.8242 20.708 30.4531 21.7627 29.7109 22.6221C29.2422 23.1592 28.7539 23.5254 28.2461 23.7207C28.627 23.7207 29.1738 24.0479 29.8867 24.7021C30.9512 25.6885 31.4834 27.0361 31.4834 28.7451C31.4834 30.542 30.8584 32.124 29.6084 33.4912C28.3682 34.8486 26.5273 35.5273 24.0859 35.5273C21.0781 35.5273 18.9883 34.5459 17.8164 32.583C17.2012 31.5381 16.8594 30.1709 16.791 28.4814H20.8926Z" fill="white"/>
                        </svg>


                        </div>
                        <div className='text'>
                          <h4>Wireframes and Design</h4>
                          <p>We developed wireframes and mock-ups to:</p>
                          <ul className='circle-list'>
                            <li>Visualize the dashboard's structure and navigation functionality.</li>
                            <li>Define formatting such as font, colors, and theme in-line with the organization's branding guidelines.</li>
                            <li>Define Key Performance Indicators (KPIs), visuals, slicers, drill-downs, etc. to convey information efficiently.</li>
                          </ul>
                          <p>The output of this stage is a mock-up of the dashboard, helping the client visualize the expected final output.</p>
                        </div>
                      </div>



                      <div className='method-stage mt-5'> <span>PHASE 02 </span>DEVELOPMENT</div>

                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                          <path d="M31.6885 31.4297H29.2861V36H25.1992V31.4297H16.791V27.7822L24.5986 14.8916H29.2861V28.1631H31.6885V31.4297ZM25.1992 28.1631V18.9932L19.8818 28.1631H25.1992Z" fill="white"/>
                        </svg>



                        </div>
                        <div className='text'>
                          <h4>Dashboard Development</h4>
                          <p>To transform raw data into actionable insights, we created visual and informative dashboards:</p>
                          <ul className='circle-list'>
                            <li>Data Structuring, Transformation, and Modelling: We established a data infrastructure by cleaning, structuring, 
                              and integrating data sources into a robust data model.</li>
                            <li>Visual Storytelling: We created a user-friendly dashboard that effectively communicates key performance 
                              indicators and trends aligned with design specifications and collated stakeholder requirements.</li>
                          </ul>
                          <p>By following these steps, we developed a set of unified BI dashboards that deliver clear and valuable insights.</p>

                        </div>
                      </div>
    
                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                         <path d="M23.998 25.4531C23.4902 25.4531 23.0508 25.5166 22.6797 25.6436C22.0254 25.8779 21.5322 26.3125 21.2002 26.9473L17.4502 26.7715L18.9443 15.0381H30.6484V18.583H21.9619L21.2002 23.2266C21.8447 22.8066 22.3477 22.5283 22.709 22.3916C23.3145 22.167 24.0518 22.0547 24.9209 22.0547C26.6787 22.0547 28.2119 22.6455 29.5205 23.8271C30.8291 25.0088 31.4834 26.7275 31.4834 28.9834C31.4834 30.9463 30.8535 32.6992 29.5938 34.2422C28.334 35.7852 26.4492 36.5566 23.9395 36.5566C21.918 36.5566 20.2578 36.0146 18.959 34.9307C17.6602 33.8467 16.9375 32.3086 16.791 30.3164H20.9512C21.1172 31.2246 21.4346 31.9277 21.9033 32.4258C22.3721 32.9141 23.0557 33.1582 23.9541 33.1582C24.9893 33.1582 25.7754 32.7969 26.3125 32.0742C26.8594 31.3418 27.1328 30.4238 27.1328 29.3203C27.1328 28.2363 26.8789 27.3232 26.3711 26.5811C25.8633 25.8291 25.0723 25.4531 23.998 25.4531Z" fill="white"/>
                        </svg>


                        </div>
                        <div className='text'>
                          <h4>Deployment & Access Setup</h4>
                          
                          <p>To ensure successful dashboard delivery and optimal performance, we followed a quality assurance and deployment process:</p>
                          <ul className='circle-list'>
                            <li><b>User Acceptance Testing (UAT):</b> We conducted thorough testing to evaluate accuracy, functionality, and user experience. 
                            We collected regular feedback and incorporated necessary refinements.</li>
                            <li><b>Deployment:</b> After securing the required licenses from the client, we seamlessly integrated the dashboard into their IT infrastructure. 
                            Comprehensive user access controls and automated data refresh mechanisms were established to optimize performance and security.</li>
                          </ul>
                          <p>This collaborative effort successfully deployed the BI dashboard to the client's environment.</p>
                        </div>
                      </div>       


                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                          <path d="M25.1699 22.2158C27.0547 22.2158 28.5977 22.8555 29.7988 24.1348C31.0098 25.4141 31.6152 27.0498 31.6152 29.042C31.6152 31.0244 31.0244 32.7725 29.8428 34.2861C28.6611 35.7998 26.8252 36.5566 24.335 36.5566C21.6592 36.5566 19.6865 35.4385 18.417 33.2021C17.4307 31.4541 16.9375 29.1982 16.9375 26.4346C16.9375 24.8135 17.0059 23.4951 17.1426 22.4795C17.3867 20.6729 17.8604 19.1689 18.5635 17.9678C19.1689 16.9424 19.96 16.1172 20.9365 15.4922C21.9229 14.8672 23.0996 14.5547 24.4668 14.5547C26.4395 14.5547 28.0117 15.0625 29.1836 16.0781C30.3555 17.084 31.0146 18.4268 31.1611 20.1064H27.001C27.001 19.7646 26.8691 19.3887 26.6055 18.9785C26.1562 18.3145 25.4775 17.9824 24.5693 17.9824C23.2119 17.9824 22.2451 18.7441 21.6689 20.2676C21.3564 21.1074 21.1416 22.3477 21.0244 23.9883C21.542 23.373 22.1426 22.9238 22.8262 22.6406C23.5098 22.3574 24.291 22.2158 25.1699 22.2158ZM22.3574 26.2002C21.5371 26.835 21.127 27.8604 21.127 29.2764C21.127 30.4189 21.4346 31.3516 22.0498 32.0742C22.665 32.7969 23.4463 33.1582 24.3936 33.1582C25.3213 33.1582 26.0488 32.8115 26.5762 32.1182C27.1133 31.415 27.3818 30.5068 27.3818 29.3936C27.3818 28.1533 27.0791 27.2061 26.4736 26.5518C25.8682 25.8877 25.126 25.5557 24.2471 25.5557C23.5342 25.5557 22.9043 25.7705 22.3574 26.2002Z" fill="white"/>
                        </svg>

                        </div>
                        <div className='text'>
                          <h4>Training & User Manuals</h4>
                          <p>We organized workshops and developed comprehensive user manuals to equip users with the knowledge to navigate and understand the dashboard. This involved:</p>
                          <ul className='circle-list'>
                            <li><b>Thoroughly understanding the target audience to tailor content and structure accordingly.</b></li>
                            <li><b>Providing clear explanations and instructions in a user-friendly format.</b></li>
                            <li><b>Incorporating visuals and diagrams to clarify complex concepts.</b></li>
                            <li><b>Gathering user feedback to refine the manual for optimal usability.</b></li>
                          </ul>
                        </div>
                      </div>     

                      <div className='method-stage mt-5'> <span>PHASE 03 </span>MAINTENANCE</div>

                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                          <path d="M29.2129 21.5859C28.0996 23.168 27.167 24.7988 26.415 26.4785C25.8193 27.7969 25.2822 29.4082 24.8037 31.3125C24.3252 33.2168 24.0859 34.7793 24.0859 36H19.75C19.877 32.1914 21.127 28.2314 23.5 24.1201C25.0332 21.5713 26.3174 19.7939 27.3525 18.7881H16.7617L16.8203 15.0381H31.8203V18.3047C31.1953 18.9199 30.3262 20.0137 29.2129 21.5859Z" fill="white"/>
                        </svg>



                        </div>
                        <div className='text'>
                          <h4>Enhancement and Maintenance Support</h4>
                          <p>We provide enhancement and maintenance support to ensure the dashboard remains a valuable, functional, and adaptable business tool. This includes:</p>
                          <ul className='circle-list'>
                            <li>Incremental development based on evolving business requirements and incorporating new data sources or KPIs.</li>
                            <li>Assessing and addressing any issues, such as data format or structure changes, performance, and accuracy.</li>
                          </ul>
                        </div>
                      </div>
    
             
                      <h3 className='mt-5'>Sample Output</h3>
                      <div className='sample-output1 mt-3'>
                        <img src={sm1} alt=''/>
                        <img src={sm2} alt=''/>
                      </div>
            
                  </div>
                  
                </div>
            </Row>
          </Container>
        </section>

    
        <section className='casestudybody21-section outcome'  >
          <Container>
            <Row className='w-100 pb-5 clintrow '>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <div className="h1">Outcome</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>The deployment of organization-wide dashboards across HR, finance, project management, infrastructure, and other functions equipped the client with an enhanced medium for extracting actionable insights from their data. The overall solution improved data accuracy and accessibility, enabling function leaders to make informed decisions based on reliable insights.</p>  
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={outcome} alt="" />
                </div>
            </Row>
       
          </Container>
        </section>
    
        </>  
      )
    }

export default Casestudybody22
