import React from 'react'
import Menu from '../component/Menu';
import HeaderSolution from '../component/HeaderSolution'
import SolutionDes from '../component/SolutionDes'
import SolutionBox from '../component/SolutionBox'
import Getintouch2 from '../component/Getintouch2'
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const Solution = ({setBlank}) => {
  return (
    <>
      {/* <Parent> */}
        <Helmet>
          <title>Who We Serve</title>
          <meta property="og:title" content="Who We Serve" />
          <meta name='og:description' content='Who We Serve' />
          <meta name='keywords' content='' />
        </Helmet>
        {/* <Children>
          
        </Children> */}
      {/* </Parent> */}
      <Menu setBlank={setBlank} />
      <HeaderSolution setBlank={setBlank} />
      <SolutionDes />
      <SolutionBox setBlank={setBlank}  />
      <Getintouch2 setBlank={setBlank}  />
      <Footer setBlank={setBlank} />
    </>
  )
}

export default Solution