import React from 'react'
import './thumbnailimg.css'

const ThumbnailImg = ({img}) => {
  return (
    <div className="thumbnail">
      <img src={img} alt=""/>
    </div>
  )
}

export default ThumbnailImg