import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './casestudybody7.css';

import clientimg from '../images/case-study-client7.webp'
import outcome from '../images/outcome7.webp'

import sm1 from '../images/cs7so1.webp'
import sm2 from '../images/cs7so2.webp'
import sm3 from '../images/cs7so3.webp'
import sm4 from '../images/cs7so4.webp'



const Casestudybody7 = () => {

    // const scrollTop = () => {
    //     window.scrollTo(0, window.innerHeight / 2)
    //   }

    return (
        <>
        <section className='casestudybody7-section'  >
          <Container>
          {/* <Row className='w-100 pt-md-5 pt-5'>
            <div className='col-md-12 '>
            <ul class="breadcrumb">
              <li className='last'><NavLink onClick={scrollTop} to={`/knowledge`} >
                     Knowledge </NavLink></li>
              <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.5 15L13.5 12L10.5 9" stroke="#151515"/>
                </svg></li>
              <li>Case Study 1</li>
              </ul>
            </div>
          </Row>       */}
            <Row className='w-100 py-md-5 py-4 clintrow'>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <h2>About</h2>
                      {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                      <div className="h1">The Client</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>The client is a real estate development company, established in 2021 and owned by a renowned sovereign wealth fund. They operate across a spectrum of segments including Residential, Commercial, Hospitality, Retail, Warehouse, Parking, Social Infrastructure, and more. </p>
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={clientimg} alt="" />
                </div>
            </Row>
       
            <Row className='mt-md-5'>
              <div className='col-md-12'>
                <div className='content-box'>
                  <h2>Situation</h2>
                  {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                  <div className="h1">Overview</div>
                  <p className='anima '><span className='only-anima-underline' > **** </span></p>
                  <p>The company embarked on revitalizing and managing assets within a historic district, with a robust <strong>SAR 8.0 Bn budget</strong>. They aimed to develop a corporate strategy outlining strategic pillars, objectives, and an execution roadmap.</p>
                </div>
                
              </div>
            </Row>
          </Container>
        </section>
    
        <section className='casestudybody7-section bg-gredient-dark'>
          <Container>
            <Row>
              <div className='col-md-12'>
                  <div className='content-box'>
                    <h2>Approach &</h2>
                    {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                    <div className="h1">Methodology</div>
                    <p className='anima '><span className='only-anima-underline' > **** </span></p>
                    <p><i>Preferred Square took a holistic approach to this project, involving:</i></p>
                  
                    <div className='list-text my-3'>
                        <div className='icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                          <path d="M32 34.6673V5.33398L53.3333 16.0007L32 26.6673" stroke="#EB1A78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M54.7992 27.2805C56.2182 31.6542 56.351 36.3435 55.1817 40.7905C54.0124 45.2375 51.5902 49.255 48.2034 52.365C44.8165 55.475 40.6075 57.5466 36.0771 58.3334C31.5468 59.1201 26.8858 58.5889 22.6487 56.8029C18.4115 55.0169 14.7767 52.0513 12.1765 48.2589C9.5763 44.4665 8.12027 40.007 7.98162 35.411C7.84297 30.8149 9.02754 26.2757 11.3944 22.3335C13.7613 18.3913 17.2108 15.212 21.3325 13.1738" stroke="#EB1A78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M21.3332 26.668C19.9969 28.4467 19.127 30.5315 18.8026 32.7325C18.4783 34.9334 18.7098 37.1806 19.4761 39.2692C20.2424 41.3578 21.5192 43.2214 23.1901 44.6903C24.8609 46.1592 26.8728 47.1866 29.0423 47.6791C31.2119 48.1715 33.4702 48.1132 35.6114 47.5095C37.7527 46.9059 39.7089 45.776 41.3018 44.2228C42.8946 42.6697 44.0735 40.7427 44.7311 38.6174C45.3887 36.492 45.504 34.2359 45.0665 32.0546" stroke="#EB1A78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </div>
                        <div className='text'>
                          <h4>Client Goals Identification & Understanding</h4>
                          <p>We engaged with the client to grasp their current business landscape and future project aspirations.</p>
                        </div>
                      </div>
    
                      <div className='list-text my-3'>
                        <div className='icon'>
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.5" y="0.5" width="63" height="63" stroke="black"/>
                          <path d="M54.8566 32C56.7689 32.9919 58.4 34.4504 59.5987 36.2404C60.7973 38.0304 61.5248 40.094 61.7137 42.24C61.7137 51.0629 48.4109 58.24 31.9994 58.24C15.588 58.24 2.28516 51.1086 2.28516 42.2857C2.46664 40.1317 3.19067 38.0588 4.38972 36.2602C5.58878 34.4616 7.2238 32.996 9.1423 32" stroke="#EB1A78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M32.0001 40.9938C35.9168 37.5854 39.0126 34.1194 41.2876 30.5958C43.5626 27.0708 44.7001 24.0222 44.7001 21.45C44.7001 17.6389 43.4939 14.4931 41.0814 12.0125C38.6689 9.53194 35.6418 8.29236 32.0001 8.29375C28.3599 8.29375 25.3328 9.53333 22.9189 12.0125C20.505 14.4917 19.2987 17.6375 19.3001 21.45C19.3001 24.0222 20.4376 27.0708 22.7126 30.5958C24.989 34.1194 28.0849 37.5868 32.0001 40.9938ZM32.0001 42.825C31.6737 42.825 31.3474 42.7715 31.021 42.6646C30.696 42.5576 30.396 42.384 30.121 42.1438C28.639 40.7826 27.1369 39.2653 25.6147 37.5917C24.0911 35.9194 22.7078 34.1882 21.4647 32.3979C20.2217 30.6063 19.2029 28.7778 18.4085 26.9125C17.614 25.0472 17.2168 23.2264 17.2168 21.45C17.2168 17.0431 18.6515 13.4083 21.521 10.5458C24.3918 7.68194 27.8849 6.25 32.0001 6.25C36.1154 6.25 39.6085 7.68194 42.4793 10.5458C45.3487 13.4083 46.7835 17.0431 46.7835 21.45C46.7835 23.2264 46.3862 25.041 45.5918 26.8938C44.7974 28.7451 43.7849 30.5729 42.5543 32.3771C41.3251 34.1826 39.9494 35.9146 38.4272 37.5729C36.9036 39.2313 35.4008 40.7417 33.9189 42.1042C33.6536 42.3444 33.3522 42.525 33.0147 42.6458C32.6758 42.7653 32.3376 42.825 32.0001 42.825ZM32.0064 24.4396C32.9342 24.4396 33.7258 24.109 34.3814 23.4479C35.0369 22.7882 35.3647 21.9944 35.3647 21.0667C35.3647 20.1389 35.0349 19.3472 34.3751 18.6917C33.714 18.0361 32.9203 17.7083 31.9939 17.7083C31.0675 17.7083 30.2758 18.0389 29.6189 18.7C28.9633 19.3597 28.6355 20.1535 28.6355 21.0813C28.6355 22.009 28.9654 22.8007 29.6251 23.4563C30.2862 24.1118 31.08 24.4396 32.0064 24.4396Z" fill="#F11576"/>
                        </svg>

                        </div>
                        <div className='text'>
                          <h4>Site Visit</h4>
                          <p>Conducting on-site inspections, we collaborated with the management for a thorough perspective of the revitalization effort.</p>
                        </div>
                      </div>
    
                      <div className='list-text  my-3'>
                        <div className='icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                          <g clip-path="url(#clip0_6238_1893)">
                          <path d="M8.75 12.25C8.28587 12.25 7.84075 12.0656 7.51256 11.7374C7.18437 11.4092 7 10.9641 7 10.5C7 10.0359 7.18437 9.59075 7.51256 9.26256C7.84075 8.93437 8.28587 8.75 8.75 8.75H47.25C47.7141 8.75 48.1592 8.93437 48.4874 9.26256C48.8156 9.59075 49 10.0359 49 10.5C49 10.9641 48.8156 11.4092 48.4874 11.7374C48.1592 12.0656 47.7141 12.25 47.25 12.25H8.75ZM15.75 5.25C15.2859 5.25 14.8408 5.06563 14.5126 4.73744C14.1844 4.40925 14 3.96413 14 3.5C14 3.03587 14.1844 2.59075 14.5126 2.26256C14.8408 1.93437 15.2859 1.75 15.75 1.75H40.25C40.7141 1.75 41.1592 1.93437 41.4874 2.26256C41.8156 2.59075 42 3.03587 42 3.5C42 3.96413 41.8156 4.40925 41.4874 4.73744C41.1592 5.06563 40.7141 5.25 40.25 5.25H15.75ZM0 45.5C0 46.8924 0.553123 48.2277 1.53769 49.2123C2.52226 50.1969 3.85761 50.75 5.25 50.75H50.75C52.1424 50.75 53.4777 50.1969 54.4623 49.2123C55.4469 48.2277 56 46.8924 56 45.5V21C56 19.6076 55.4469 18.2723 54.4623 17.2877C53.4777 16.3031 52.1424 15.75 50.75 15.75H5.25C3.85761 15.75 2.52226 16.3031 1.53769 17.2877C0.553123 18.2723 0 19.6076 0 21L0 45.5ZM5.25 47.25C4.78587 47.25 4.34075 47.0656 4.01256 46.7374C3.68437 46.4092 3.5 45.9641 3.5 45.5V21C3.5 20.5359 3.68437 20.0908 4.01256 19.7626C4.34075 19.4344 4.78587 19.25 5.25 19.25H50.75C51.2141 19.25 51.6592 19.4344 51.9874 19.7626C52.3156 20.0908 52.5 20.5359 52.5 21V45.5C52.5 45.9641 52.3156 46.4092 51.9874 46.7374C51.6592 47.0656 51.2141 47.25 50.75 47.25H5.25Z" fill="#F11576"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_6238_1893">
                          <rect width="56" height="56" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                        </div>

                        <div className='text'>
                          <h4>Data Collection</h4>
                          <p>We organized workshops with management to collect and analyze relevant data.</p>
                        </div>
                      </div>

                      <div className='list-text  my-3'>
                        <div className='icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                          <path d="M11.6673 27.9993C11.0485 27.9993 10.455 28.2452 10.0174 28.6828C9.57982 29.1204 9.33398 29.7138 9.33398 30.3327V48.9993C9.33398 49.6182 9.57982 50.2117 10.0174 50.6493C10.455 51.0868 11.0485 51.3327 11.6673 51.3327C12.2862 51.3327 12.8796 51.0868 13.3172 50.6493C13.7548 50.2117 14.0007 49.6182 14.0007 48.9993V30.3327C14.0007 29.7138 13.7548 29.1204 13.3172 28.6828C12.8796 28.2452 12.2862 27.9993 11.6673 27.9993ZM23.334 4.66602C22.7151 4.66602 22.1217 4.91185 21.6841 5.34943C21.2465 5.78702 21.0007 6.38051 21.0007 6.99935V48.9993C21.0007 49.6182 21.2465 50.2117 21.6841 50.6493C22.1217 51.0868 22.7151 51.3327 23.334 51.3327C23.9528 51.3327 24.5463 51.0868 24.9839 50.6493C25.4215 50.2117 25.6673 49.6182 25.6673 48.9993V6.99935C25.6673 6.38051 25.4215 5.78702 24.9839 5.34943C24.5463 4.91185 23.9528 4.66602 23.334 4.66602ZM46.6673 37.3327C46.0485 37.3327 45.455 37.5785 45.0174 38.0161C44.5798 38.4537 44.334 39.0472 44.334 39.666V48.9993C44.334 49.6182 44.5798 50.2117 45.0174 50.6493C45.455 51.0868 46.0485 51.3327 46.6673 51.3327C47.2862 51.3327 47.8797 51.0868 48.3172 50.6493C48.7548 50.2117 49.0006 49.6182 49.0006 48.9993V39.666C49.0006 39.0472 48.7548 38.4537 48.3172 38.0161C47.8797 37.5785 47.2862 37.3327 46.6673 37.3327ZM35.0007 18.666C34.3818 18.666 33.7883 18.9118 33.3507 19.3494C32.9132 19.787 32.6673 20.3805 32.6673 20.9993V48.9993C32.6673 49.6182 32.9132 50.2117 33.3507 50.6493C33.7883 51.0868 34.3818 51.3327 35.0007 51.3327C35.6195 51.3327 36.213 51.0868 36.6506 50.6493C37.0882 50.2117 37.334 49.6182 37.334 48.9993V20.9993C37.334 20.3805 37.0882 19.787 36.6506 19.3494C36.213 18.9118 35.6195 18.666 35.0007 18.666Z" fill="#F11576"/>
                        </svg>
                        </div>

                        <div className='text'>
                          <h4>Internal Business Assessment</h4>
                          <p>Analyzing the collected data, we defined the company's vision, mission, and values, identifying operational strengths, challenges, opportunities, and threats through SWOT and stakeholder analysis.</p>
                        </div>
                      </div>

                      <div className='list-text  my-3'>
                        <div className='icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                          <path d="M56.0005 56.0005L44.4192 44.4192M44.4192 44.4192C46.4003 42.4382 47.9717 40.0864 49.0438 37.498C50.1159 34.9097 50.6678 32.1355 50.6678 29.3339C50.6678 26.5323 50.116 23.7581 49.0438 21.1698C47.9717 18.5814 46.4003 16.2296 44.4192 14.2485C42.4382 12.2675 40.0864 10.6961 37.498 9.62395C34.9097 8.55182 32.1355 8 29.3339 8C26.5323 8 23.7581 8.55182 21.1698 9.62395C18.5814 10.6961 16.2296 12.2675 14.2485 14.2485C10.2477 18.2494 8 23.6758 8 29.3339C8 34.992 10.2477 40.4183 14.2485 44.4192C18.2494 48.4201 23.6758 50.6678 29.3339 50.6678C34.992 50.6678 40.4183 48.4201 44.4192 44.4192Z" stroke="#F11576" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>

                        <div className='text'>
                          <h4>Market Research</h4>
                          <p>We conducted a comprehensive market evaluation across various segments to identify growth and profitability opportunities, while mitigating risks and enhancing the company's competitive stance.</p>
                        </div>
                      </div>

                      <div className='list-text  my-3'>
                        <div className='icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                          <path d="M16 54.6667C18.6667 29.3333 30.6667 21.3333 53.3333 16" stroke="#F11576" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M42.424 10.16L54.3867 15.7333L48.808 27.696M42.6667 53.9813L48.3227 48.3227M48.3227 48.3227L53.9813 42.6667M48.3227 48.3227L42.6667 42.6667M48.3227 48.3227L53.9813 53.9813M13.3333 18.6667C14.7478 18.6667 16.1044 18.1048 17.1046 17.1046C18.1048 16.1044 18.6667 14.7478 18.6667 13.3333C18.6667 11.9188 18.1048 10.5623 17.1046 9.5621C16.1044 8.5619 14.7478 8 13.3333 8C11.9188 8 10.5623 8.5619 9.5621 9.5621C8.5619 10.5623 8 11.9188 8 13.3333C8 14.7478 8.5619 16.1044 9.5621 17.1046C10.5623 18.1048 11.9188 18.6667 13.3333 18.6667Z" stroke="#F11576" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </div>

                        <div className='text'>
                          <h4>Defining Strategic Choices</h4>
                          <p>Informed by earlier insights, we defined strategic pillars, objectives, and projects, aligning them with the client's unique value proposition and vision.</p>
                        </div>
                      </div>

                      <h3 className='mt-5'>Sample Output</h3>
                      <div className='sample-output mt-3'>
                        <img src={sm1} alt=''/>
                        <img src={sm2} alt=''/>
                        <img src={sm3} alt=''/>
                        <img src={sm4} alt=''/>
                      </div>
                  </div>
                  
                </div>
            </Row>
          </Container>
        </section>

    
        <section className='casestudybody7-section outcome'  >
          <Container>
            <Row className='w-100 pb-5 clintrow '>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <div className="h1">Outcome</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>Our efforts led to the presentation of a detailed roadmap featuring strategic pillars, objectives, and projects. This new corporate strategy provides the company with a clear direction for sustainable growth and long-term goal achievement.</p>  
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={outcome} alt="" />
                </div>
            </Row>
       
          </Container>
        </section>
    
        </>  
      )
    }

export default Casestudybody7
