import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './casestudybody21.css';

import clientimg from '../images/case-study-client21.webp'
import outcome from '../images/outcome21.webp'

import sm1 from '../images/cs21so1.webp'
import sm2 from '../images/cs21so2.webp'
import sm3 from '../images/cs21so3.webp'
import sm4 from '../images/cs21so4.webp'
import sm5 from '../images/cs21so5.webp'
import sm6 from '../images/cs21so6.webp'



const Casestudybody21 = () => {

    return (
        <>
        <section className='casestudybody21-section'  >
          <Container>

            <Row className='w-100 py-md-5 py-4 clintrow'>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <h2>About</h2>
                      {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                      <div className="h1">The Client</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>The client is a family office governing and managing a multi-asset class portfolio worth USD 15 billion.</p>
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={clientimg} alt="" />
                </div>
            </Row>
       
            <Row className='mt-md-5'>
              <div className='col-md-12'>
                <div className='content-box'>
                  <h2>Situation</h2>
                  {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                  <div className="h1">Overview</div>
                  <p className='anima '><span className='only-anima-underline' > **** </span></p>
                  <p>They sought a corporate strategy that defined their vision, objectives, and goals. The strategy included an execution roadmap to optimize asset management, mitigate risk by creating multiple subsidiaries, and enhance efficiency by optimizing resources.</p>
                </div>
                
              </div>
            </Row>
          </Container>
        </section>
    
        <section className='casestudybody21-section bg-gredient-dark'>
          <Container>
            <Row>
              <div className='col-md-12'>
                  <div className='content-box'>
                    <h2>Approach &</h2>
                    {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                    <div className="h1">Methodology</div>
                    <p className='anima '><span className='only-anima-underline' > **** </span></p>
                    {/* <p>The next step was to put together and implement a step-by-step process for conducting the valuation:</p> */}
                  
                    <div className='list-text my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>
                        </div>
                        <div className='text'>
                          <h4>Kick-Off Meeting:</h4>
                          {/* <p>To initiate the corporate strategy development, we conducted a kick-off meeting with the senior management to get alignment from the strategy framework and key objectives.</p> */}
                          <p>To initiate the corporate strategy development, we conducted a kick-off meeting with the senior management to <span style={{fontWeight:700}}>get alignment on</span> the strategy framework and key objectives.</p>
                        </div>
                      </div>
    
                      <div className='list-text my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>
                        </div>
                        <div className='text'>
                          <h4>Data Collection:</h4>
                          <p>Conducted workshops with the management and team members to gather insights into the company's operations, challenges, and opportunities.</p>
                        </div>
                      </div>
    
                      <div className='list-text my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>
                        </div>

                        <div className='text'>
                          <h4>Internal Business Assessment:</h4>
                          <p>We defined the company's vision, mission, and values to establish a clear direction for growth. We also conducted a sector-wide assessment to understand the competitive landscape, the company's SWOT, GTM, and other business-centric details.</p>
                        </div>
                      </div>

                      <div className='list-text my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>
                        </div>

                        <div className='text'>
                          <h4>Target State Definition:</h4>
                          <p>Ensured that the target state for the company's portfolio was aligned with the shareholder guidance, the board of directors' expectations, and market trends. Developed a plan guiding the steps to <span style={{fontWeight:700}}>achieve the desired target state</span>, including strategic initiatives, resource allocation, and timelines.</p>
                        </div>
                      </div>


                      <div className='list-text last-child my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>
                        </div>

                        <div className='text'>
                          <h4>Strategic Choices Definition:</h4>
                          <p>After a quantitative analysis of  <span style={{fontWeight:700}}>costs</span>, benefits, and risks, various strategic options were identified to re-prioritize efforts and resources.</p>
                        </div>
                      </div>

                     
                      <h3 className='mt-5'>Sample Output</h3>
                      <div className='sample-output mt-3'>
                        <img src={sm1} alt=''/>
                        <img src={sm2} alt=''/>
                        <img src={sm3} alt=''/>
                        <img src={sm4} alt=''/>
                        <img src={sm5} alt=''/>
                        <img src={sm6} alt=''/>
                      </div>
                  </div>
                  
                </div>
            </Row>
          </Container>
        </section>

    
        <section className='casestudybody21-section outcome'  >
          <Container>
            <Row className='w-100 pb-5 clintrow '>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <div className="h1">Outcome</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>We delivered a 10-year corporate strategy to the client, outlining a detailed roadmap to achieve their desired portfolio objectives.</p>  
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={outcome} alt="" />
                </div>
            </Row>
       
          </Container>
        </section>
    
        </>  
      )
    }

export default Casestudybody21
