import React from 'react'
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody from '../component/Casestudybody22';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy22 = ({setBlank}) => {
  
  
    const headings = "<h2 style='display:inline-block;color:#ffffff;'> Customized BI Dashboards </h2><h4>for an Investment Corporate</h4>";
  return (
    <>
        <Helmet>
          <title>Customized BI Dashboards for an Investment Corporate</title>
          <meta property="og:title" content="Customized BI Dashboards for an Investment Corporate" />
          <meta property='og:description' content='Customized BI Dashboards for an Investment Corporate'/>
          <meta name='keywords' content=''/>
        </Helmet>
        <Menu setBlank={setBlank}  />
        <HeaderCasestudy headings={headings} class="header-casestudy22" />  
        <Casestudybody />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default CaseStudy22
