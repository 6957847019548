import React from 'react'
import Container from 'react-bootstrap/Container';
import './headerterms.css'
const Headerterms = (props) => {
  return (
    <header className='header-terms'> 
        <Container fluid className='hero px-5 pt-3'>
        
        {/* hero */}
        <div className='hero-content'>
            
            <div className='hero-text'>
                <h2>{props.title}</h2>
            </div>
        </div>

        </Container>
    </header>

  )
}

export default Headerterms
