import React from 'react'
// import HeaderPP from '../component/HeaderPP'
import Menu from '../component/Menu'
import Footer from '../component/Footer'
import PPbody from '../component/PPbody'
import Headerterms from '../component/HeaderTerms'
import { Helmet } from 'react-helmet';

const Privacypolicy = (setBlank) => {

  <Helmet>
    <title>Privacy Policy</title>
    <meta property="og:title" content="Privacy Policy" />
    <meta name='og:description' content='Privacy Policy'/>
    <meta name='keywords' content=''/>
  </Helmet>

  return (
    <>
       <Menu setBlank={setBlank} />
       {/* <HeaderPP />  */}
       <Headerterms title="Privacy Policy" />
       <PPbody />
       <Footer setBlank={setBlank} />
    </>
  )
}

export default Privacypolicy
