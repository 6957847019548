import React from 'react'
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody from '../component/Casestudybody7';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy7 = ({setBlank}) => {

    const headings = "<h2 style='display:inline-block;color:#ffffff;'> Corporate Strategy</h2><h4>For a Real Estate Development Company</h4>";

  return (
    <>
        <Helmet>
        <title>Corporate Strategy For A Real Estate Development Company</title>
        <meta property="og:title" content="Corporate Strategy For A Real Estate Development Company" />
        <meta property="og:image" content="https://preferred-square.pages.dev/static/media/case-study-client7.160c7e4678769deceb9f.webp" /> 
        <meta property='og:description' content='Corporate Strategy For A Real Estate Development Company'/>
        <meta name='keywords' content=''/>
        </Helmet>

        <Menu setBlank={setBlank}  />
        <HeaderCasestudy headings={headings} class="header-casestudy7" />  
        <Casestudybody />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default CaseStudy7
