import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './wehelp.css';
import img1 from '../images/strategy_consulting.webp'
import img2 from '../images/business_operations.webp'
import img3 from '../images/organizational_consulting.webp'

const Wehelp = () => {
    return (

        <>

            <section className='wehelp-section'>
                <Container>
                    <Row className=''>
                        <Col>
                            <div className='content-box'>
                                <div className="h3">How</div>
                                <div className="strok">We Can <span className='ml-strok'>Help</span></div>
                                <div className="h1 anima desktop"><span className='anima-underline'>We C</span>an <span className='ml'>Help</span></div>
                                <div className="h1 anima mobile">We Can <span className='anima-underline'>Help</span></div>                      
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5 mobilemargin1'>
                        <Col className='col-md-6'>
                            {/* <div className='gredient-border'> */}
                            <img src={img1} alt='' />
                            {/* </div> */}
                            <div className='img-text'>
                                <h3 className='img-heading'>Strategy Consulting</h3>                                
                                <p className='img-paragraph'>Enabling businesses by solving their problems and providing practical solutions that drive tangible results and unlock their potential </p>
                                {/* <p className='img-paragraph'>Through rigorous analysis, market insights, and a deep understanding of industry dynamics, our seasoned consultants collaborate closely with clients to formulate winning market entry strategies to achieve their growth goals. </p> */}
                            </div>
                        </Col>
                        <Col className='col-md-6'>
                            <div className='text-right side-div padding-div2 mt-lg-4 relative-positin'>
                                <div className='marker'></div>
                                <h4>Strategy development</h4>
                                <p>Identify new opportunities, optimize existing portfolio and align business units for  maximum operational effectiveness. </p>
    
                                <ul className='key-list blue'>
                                    <li>Corporate Strategy</li>
                                    <li>Business Strategy</li>
                                    <li>Growth Strategy</li>
                                    <li>Market Entry Strategy</li>
                                    <li>Turnaround Strategy</li>
                                </ul>


                                <div className='marker'></div>
                                <h4>Business plans</h4>
                                <p>Align resources towards a unified vision of creating exceptional value for all your stakeholders and gaining competitive advantage. </p>
                                <ul className='key-list blue'>
                                    <li>Company Overview</li>
                                    <li>Market Analysis</li>
                                    <li>Organization & Management Overview</li>
                                    <li>Business Model</li>
                                    <li>Operating Model</li>
                                    <li>Funding Strategy</li>
                                    <li>Financial Projections</li>
                                    <li>Risk Mitigation Strategy</li>
                                </ul>

                                <div className='marker'></div>
                                <h4>Investment strategy  </h4>
                                <p>Optimize investment decisions, identify lucrative opportunities and mitigate risk through a robust investment strategy aligned with your financial goals.</p>
                            
                                <ul className='key-list blue'>
                                    <li>Portfolio Assessment </li>
                                    <li>Investment Policy Statement</li>
                                    <li>Risk Profiling</li>
                                    <li>Investment Horizon</li>
                                    <li>Targeted Return Monitoring</li>
                                    <li>Portfolio Monitoring & Rebalancing</li>
                                </ul>

                                <div className='marker'></div>
                                <h4>Strategy Implementation</h4>
                                <p>Achieve your organizational goals by translating your strategic plans into actionable initiatives through a well crafted implementation strategy.</p>
                                <ul className='key-list blue'>
                                    <li>Process Optimization</li>
                                    <li>Change Management</li>
                                    <li>Performance Measurement & Optimization</li>
                                </ul>

                                {/* <div className='marker'></div>
                                <h4>Go-to-market strategy</h4>
                                <p>Expand into new geographies, launch products and grow revenue through customized strategies aligned with the business objectives</p> */}
                            </div>

                        </Col>
                    </Row>
                    <Row className='mt-5 py-5 mobilemargin2'>
                        <Col className='col-md-6 order1'>
                            <div className='text-left side-div mobilemargin2 relative-positin'>
                                <div>
                                    <div className='marker2'></div>

                                    <h4>Performance management</h4>
                                    <p>Drive high-performance outcomes by implementing effective performance measurement systems, monitoring framework and fostering a culture of productivity.</p>
                                    <ul className='key-list pink'>
                                        <li>Performance Measurement Framework</li>
                                        <li>Dashboarding & Reporting</li>
                                        <li>Performance Appraisal System</li>
                                    </ul>

                                </div>
                                <div className=''>
                                    <div className='marker2'></div>

                                    <h4>Finance support</h4>
                                    <p>Achieve growth goals by aligning your financial budgets with corporate objectives. Develop robust investment strategies, optimize capital allocation and achieve your organization's financial objective through comprehensive solutions that maximize returns and mitigate risks.</p>
                                    <ul className='key-list pink'>
                                        <li>Financial reporting & compliance</li>
                                        <li>Cost management & optimization</li>
                                        <li>Financial systems implementation</li>
                                    </ul>

                                </div>
                                <div>
                                    <div className='marker2'></div>

                                    <h4>Organization design</h4>
                                    <p>Adapt to the evolving market dynamics and drive sustainable growth by creating structures and processes that foster agility, collaboration, and efficiency.</p>
                                    <ul className='key-list pink'>
                                        <li>Organizational Structure Design</li>
                                        <li>Roles & Reponsibilities Matrix</li>
                                        <li>Workforce Planning</li>
                                        <li>Talent Management Framework</li>
                                        <li>Succession Planning</li>
                                        <li>Culture & Values Structure</li>
                                        <li>Governance Framework</li>
                                    </ul>

                                </div>
                                <div>
                                    <div className='marker2'></div>
                                    <h4>Annual Operating Plans </h4>
                                    <p>Achieve optimum performance and effective execution through tailor made operating plans that offer a comprehensive framework to set goals, allocate resources and define key initiatives. </p>
                                    <ul className='key-list pink'>
                                        <li>Annual Roadmap</li>
                                        <li>Financial Planning & Analysis</li>
                                        <li>Monitoring & Reporting</li>
                                    </ul>

                                </div>
                                <div>
                                    <div className='marker2'></div>
                                    <h4>Process Consulting  </h4>
                                    <p>Streamline workflows through creative frameworks and flowcharts prepared on basis of industry best practices.</p>
                                    <ul className='key-list pink'>
                                        <li>Process Assessment</li>
                                        <li>Process design & Redesign</li>
                                        <li>Process Mapping & Documentation</li>
                                        <li>Technology Integration Roadmaps</li>
                                        <li>Stage-Gate Process</li>
                                    </ul>

                                </div>
                            </div>
                        </Col>
                        <Col className='col-md-6 mb-lg-5 pb-lg-3 relative'>
                            <img className='image2' src={img2} alt='' />
                            <div className='img-text img-text2'>
                                <h3 className='img-heading'>Business operations  Consulting</h3>
                                <p className='img-paragraph'>Utilizing our expertize in operations management, process optimization, and technology integration, we work closely with clients to identify bottlenecks, implement best practices, and drive operational excellence.</p>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row className='mt-lg-5 pt-lg-5 mobilemargin2'> */}
                    <Row className='mobilemargin2'>
                        <Col className='col-md-6'>
                            <img src={img3} alt='' />
                            <div className='img-text'>
                                <h3 className='img-heading'>Project Management</h3>
                                <p className='img-paragraph'>With a focus on strategic alignment, change management, and talent development, we collaborate closely with organizations to drive transformation, increase agility, and foster a high-performing work environment.</p>       
                            </div>
                        </Col>
                        <Col className='col-md-6'>
                            <div className='text-right side-div padding-div2  pt-2 mobilemargin3 relative-positin'>
                                <div className='marker'></div>
                                <h4>Enterprise Project management Office (EPMO)</h4>
                                <p>Allocate resources efficiently, mitigate risks and enhance project outcomes through a centralized framework designed to execute and control projects across the enterprise. </p>
                                <ul className='key-list blue'>
                                    <li>Project Selection & Prioritization Framework</li>
                                    <li>Project Portfolio Management</li>
                                    <li>Project Performance Management</li>
                                    <li>Project Risk Management</li>
                                    <li>Project Reports & Dashboard</li>
                                </ul>   
                               <div className=' mobilemargin2-div'>
                               <div className='marker'></div>
                                <h4>Project Management Office (PMO)</h4>
                                <p>A centralized hub focused on standardizing project management practices, ensuring organizational alignment and providing oversight and support for efficient project delivery. </p>
                                <ul className='key-list blue'>
                                    <li>Project Governance</li>
                                    <li>Project Methodology Development</li>
                                    <li>Project Documentation & Templates</li>
                                    <li>Stakeholder Management</li>
                                </ul>   
                               </div>
                               <div className=' mobilemargin2-div'>
                                <div className='marker'></div>

                                <h4>Strategic Management Office (SMO)</h4>
                                <p>Bridge the gap between planning and implementation through frameworks designed to enable effective execution and drive continous improvement.</p>
                                <ul className='key-list blue'>
                                    <li>Strategic Planning</li>
                                    <li>Strategy Development, Strategic Alignment</li>
                                    <li>Industry Landscaping</li>
                                    <li>Market Research</li>
                                    <li>Scenario Planning</li>
                                    <li>Risk Analysis</li>
                                </ul>   
                                
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Wehelp