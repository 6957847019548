import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './casestudybody23.css';

import clientimg from '../images/case-study-client23.webp'
import outcome from '../images/outcome23.webp'

import sm1 from '../images/cs23so1.webp'
import sm2 from '../images/cs23so2.webp'
import sm3 from '../images/cs23so3.webp'
import sm4 from '../images/cs23so4.webp'


const Casestudybody23 = () => {

    return (
        <>
          <section className='casestudybody23-section'  >
          <Container>

            <Row className='w-100 py-md-5 py-4 clintrow'>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <h2>About</h2>
                      {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                      <div className="h1">The Client</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>The client is a US-based PE firm specializing in mid-market transactions within the Industrial Products, Utilities, and Infrastructure space, focusing on leveraging deep industry expertise to transform companies into market leaders. </p>
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={clientimg} alt="" />
                </div>
            </Row>
       
            <Row className='mt-md-5'>
              <div className='col-md-12'>
                <div className='content-box'>
                  <h2>Situation</h2>
                  {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                  <div className="h1">Overview</div>
                  <p className='anima '><span className='only-anima-underline' > **** </span></p>
                  <p>The client aimed to enhance and expand their periodic performance report for submission to the Investment Committee (IC) while streamlining the performance monitoring process to reduce manual effort.</p>
                </div>
                
              </div>
            </Row>
          </Container>
        </section>
    
        <section className='casestudybody23-section bg-gredient-dark'>
        <Container>
            <Row>
              <div className='col-md-12'>
                  <div className='content-box'>
                    <h2>Approach &</h2>
                    {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                    <div className="h1">Methodology:</div>
                    <p className='anima '><span className='only-anima-underline' > **** </span></p>
                    
                    <div className='method-stage'> Data Aggregation & Insights</div>
                
                    <div className='list-text my-3'>
                        <div className='icon'>
                        
                        </div>
                        <div className='text'>
                            <p>Initiated the process by aggregating data and synthesizing it to generate insights. </p>
                        </div>
                      </div>
                 
                    <div className='list-text my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>


                        </div>
                        <div className='text'>
                            <p>Gathered and consolidated PortCo-level and fund-level metrics and transactions from the Fundwave platform, including:</p>
                          <ul>
                            <li>Investment transactions including acquisition price and acquired stake</li>
                            <li>Fund factsheets and PortCo details</li>
                            <li>Details Fund Cash Ins & Cash Outs</li>
                            <li>PortCo financial statements including corporate actions</li>
                            <li>Valuation Metrics and Current Valuations (if available)</li>
                            <li>Capital Calls, Distributions & Fees/Expenses</li>
                          </ul>
                        </div>
                      </div>
    
                      <div className='list-text my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>

                        </div>
                        <div className='text'>
                          <p>Conducted historical performance analysis and compared with the benchmarks.</p>
                        </div>
                      </div>

                      <div className='method-stage' style={{maxWidth:"770px"}}> Modelled Fund Cash Flow & Return Distribution</div>

                      <div className='list-text my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>


                        </div>
                        <div className='text'>
                          <p>Created a monthly cash flow summary to showcase Cash In & Cash Outs at PortCo and Fund levels.</p>
                        </div>
                      </div>

                      <div className='list-text my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>


                        </div>
                        <div className='text'>
                          <p>Calculated IRR and MoIC, DPI & RVPI of the PortCo and Fund level, considering the realized and unrealized cash flows; additionally, computed the LP & GP returns (adjusting management fees, carry interest, & performance fees) based on the fund covenants.</p>
                        </div>
                      </div>



                      <div className='method-stage'> Periodic Portfolio Review</div>

                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>



                        </div>
                        <div className='text'>
                          <p>Prepared an automated summary & dashboard for the IC, enabling the client to review the performance for individual portfolio companies & the fund as well.</p>
                        </div>
                      </div>
    
             
                      <h3 className='mt-5'>Sample Output</h3>
                      <p><span style={{fontWeight:700}}>Case in Point:</span> We meticulously prepared a comprehensive Portfolio Dashboard and an in-depth Fund Summary. These tools assisted in quickly extracting the performance summary of the fund without much client's involvement.</p>
                      <div className='sample-output1 mt-3'>
                        <img src={sm1} alt=''/>
                        <img src={sm2} alt=''/>
                        <img src={sm3} alt=''/>
                        <img src={sm4} alt=''/>
                      </div>
            
                  </div>
                  
                </div>
            </Row>
          </Container>
        </section>

    
        <section className='casestudybody21-section outcome'  >
          <Container>
            <Row className='w-100 pb-5 clintrow '>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <div className="h1">Outcome</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>The performance assessment dashboard enabled the client to quickly assess the performance of the funds & portfolio companies, extract required information for the IC, and reduce time spent creating the document.</p>  
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={outcome} alt="" />
                </div>
            </Row>
       
          </Container>
        </section>
    
        </>  
      )
    }

export default Casestudybody23
