import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../component/Menu'
import HeaderCasestudy from '../component/HeaderCasestudy'
import Casestudybody12 from '../component/Casestudybody12'
import Footer from '../component/Footer'
import thumbimg from '../images/case-study-client9.webp'
    

const CaseStudy12 = ({setBlank}) => {
    
    const headings = " <h2 style='display:inline-block'>Investment Policy Statement </h2><h4>for an Endowment Fund</h4>";

  return (
    <>
    <Helmet>
      <title>Investment Policy Statement for an Endowment Fund</title>
      <meta property="og:image" content={thumbimg} /> 
      <meta property="og:title" content="Investment Policy Statement for an Endowment Fund" />
      <meta property="og:description" content="Investment Policy Statement for an Endowment Fund"/>
    </Helmet>

    <Menu setBlank={setBlank} />
    <HeaderCasestudy headings={headings} />  
    <Casestudybody12 />
    <Footer setBlank={setBlank} />
</>

  )
}

export default CaseStudy12
