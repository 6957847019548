import React from 'react'
import Menu from './Menu'
// import Container from 'react-bootstrap/Container';
import './headersingle.css'

const Headersingle = ({setBlank}) => {
  return (
    <header className='header-single'> 
    <Menu setBlank={setBlank} />
     {/* <Container fluid className='hero px-5 pt-3'>
     
     <div className='hero-content'>
         
         <div className='hero-text'>
             <h6>Exposure to a</h6>
             <h2 className='desktop'>Fast-Paced Startup Culture</h2>
             <h2 className='mobile'>Fast-Paced</h2>
             <h3 className='mobile'>Startup Culture</h3>
         </div>
     </div>

     </Container> */}
   </header>  
  )
}

export default Headersingle
