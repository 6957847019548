import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../component/Menu'
import HeaderCasestudy from '../component/HeaderCasestudy'
import Casestudybody8 from '../component/Casestudybody8'
import Footer from '../component/Footer'
import thumbimg from '../images/case-study-client8.webp'
    

const CaseStudy8 = ({setBlank}) => {

  const headings = "<h2 style='display:inline-block'>Strategic Consulting</h2><h4>for a PropTech Real Estate Equity Crowdfunding Platform in KSA</h4>";

  return (
    <>
        <Helmet>
          <title>Strategic Consulting for a PropTech Real Estate Equity Crowdfunding Platform in KSA</title>
          <meta property="og:image" content={thumbimg} /> 
          <meta property="og:title" content="Enabling A US-Based Hedge Fund To Optimize Its Investment Strategy" />
          <meta property="og:description" content="Enabling A US-Based Hedge Fund To Optimize Its Investment Strategy"/>
        </Helmet>

        <Menu setBlank={setBlank} />
        <HeaderCasestudy headings={headings} />  
        <Casestudybody8 />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default CaseStudy8
