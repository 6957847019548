import React from 'react'
// import HeaderTandC from '../component/HeaderTandC'
import Menu from '../component/Menu'
import Footer from '../component/Footer'
import TCbody from '../component/TCbody'
import Headerterms from '../component/HeaderTerms'
import { Helmet } from 'react-helmet';

const Terms = (setBlank) => {

  <Helmet>
  <title>Terms & Conditions</title>
  <meta property="og:title" content="Terms & Conditions" />
  <meta name='og:description' content='Terms & Conditions' />
  <meta name='keywords' content='' />
</Helmet>

  return (
    <>
       <Menu setBlank={setBlank} />
       {/* <HeaderTandC />  */}
       <Headerterms title="Terms & Conditions" />
       <TCbody />
       <Footer setBlank={setBlank} />
    </>
  )
}

export default Terms
