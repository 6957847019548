import React from 'react'
import Compo404 from '../component/Compo404'
import { Helmet } from 'react-helmet';

const Page404 = () => {
  <Helmet>
    <title>404 - Page Not Found</title>
    <meta property="og:title" content="404 - Page Not Found" />
    <meta name='og:description' content='404 - Page Not Found'/>
    <meta name='keywords' content=''/>
  </Helmet>
  
  return (
    <Compo404 />
  )
}

export default Page404
