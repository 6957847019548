import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../component/Menu'
import HeaderCasestudy from '../component/HeaderCasestudy'
import Casestudybody10 from '../component/Casestudybody10'
import Footer from '../component/Footer'
import thumbimg from '../images/case-study-client10.webp'
    

const CaseStudy10 = ({setBlank}) => {
  
    const headings = "<h2 style='display:inline-block;'>Vendor & Counter Party Due Diligence </h2><h4>for a Government-Backed KSA Entity</h4>";

  return (
    <>
    <Helmet>
      <title>Vendor & CounterPartyDue Diligence For A Government-Backed KSA Entity</title>
      <meta property="og:image" content={thumbimg} /> 
      <meta property="og:title" content="Vendor & CounterParty Due Diligence For A Government-Backed KSA Entity" />
      <meta property="og:description" content="Vendor & CounterParty Due Diligence For A Government-Backed KSA Entity"/>
    </Helmet>

    <Menu setBlank={setBlank} />
    <HeaderCasestudy headings={headings} class="header-casestudy10" />  
    <Casestudybody10 />
    <Footer setBlank={setBlank} />
</>

  )
}

export default CaseStudy10
