import React from 'react'
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody2 from '../component/Casestudybody2';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy2 = ({setBlank}) => {

    const headings = "<h2>Enabling a US-based hedge fund</h2> <h4> to optimize its Investment strategy</h4>";

  return (
    <>
        <Helmet>
        <title>Enabling A US-Based Hedge Fund To Optimize Its Investment Strategy</title>
        <meta property="og:image" content="https://preferred-square.pages.dev/static/media/case-study-thumb2.631b77bcb7404b0515ed.webp" /> 
        <meta property="og:title" content="Enabling A US-Based Hedge Fund To Optimize Its Investment Strategy" />
        <meta property="og:description" content="Enabling A US-Based Hedge Fund To Optimize Its Investment Strategy"/>
        </Helmet>

        <Menu setBlank={setBlank} />
        <HeaderCasestudy headings={headings} />  
        <Casestudybody2 />
        <Footer setBlank={setBlank} />
    </>
   )
}

export default CaseStudy2
