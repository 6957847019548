import React from 'react'
import clientimg from '../images/case-study-client.jpg'
import ThumbnailImg from '../component/ThumbnailImg';
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody from '../component/Casestudybody';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy1 = ({setBlank}) => {
  // const headings = "<h2>Fast-tracking the investment process</h2><h4> through a structured Roadmap</h4>";
  const headings = "<h2>Expediting the Investment Process</h2><h4> Through a Structured Roadmap</h4>";
  
  return (
    <>
        {/* <Helmet>
        <title>Fast-Tracking The Investment Process Through A Structured Roadmap</title>
        <meta property="og:image" content="https://preferred-square.pages.dev/static/media/case-study-thumb1.7cbdad9f21b7d31a0455.webp" /> 
        <meta property="og:title" content="Fast-Tracking The Investment Process Through A Structured Roadmap" />
        <meta property="og:description" content="Fast-Tracking The Investment Process Through A Structured Roadmap"/>
     
        </Helmet> */}

        <Helmet>
            <title>Expediting the Investment Process Through a Structured Roadmap</title>         
            <meta name="description" content="..." />
            <meta name="keywords" content="..." />
            <meta property="og:title" content="Expediting the Investment Process Through a Structured Roadmap" />
            <meta property="og:description" content="..." />
            {/* <meta property="og:image" content={clientimg}  />
            <meta property="og:image:url"  content={clientimg}  /> */}
        </Helmet>
        <ThumbnailImg img={clientimg} />
        <Menu setBlank={setBlank}  />
        <HeaderCasestudy headings={headings} />  
        <Casestudybody />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default CaseStudy1
