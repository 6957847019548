import React from 'react'
import { Helmet } from 'react-helmet';
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody14 from '../component/Casestudybody14';
import Footer from '../component/Footer'

import thumbimg from '../images/case-study-client14.webp'

const CaseStudy14 = ({setBlank}) => {
    const headings = "<h2>Human resource Reporting</h2><h4>Dashboard for a Company in the Hospitality Industry</h4>";

  return (
    <>
    <Helmet>
      <title>Human resource Reporting Dashboard for a Company in the Hospitality Industry</title>
      <meta property="og:image" content={thumbimg} /> 
      <meta property="og:title" content="Human resource Reporting Dashboard for a Company in the Hospitality Industry" />
      <meta property="og:description" content="Human resource Reporting Dashboard for a Company in the Hospitality Industry"/>
    </Helmet>

    <Menu setBlank={setBlank} />
    <HeaderCasestudy headings={headings} class="header-caseStudy14" />  
    <Casestudybody14 />
    <Footer setBlank={setBlank} />
    </>

  )
}

export default CaseStudy14
