import Aboutoverview from '../component/Aboutoverview'
import Ourvalue from '../component/Ourvalue'
import './aboutus.css'

const Aboutus = () => {
  return (
    <div className='about-section'>
        <Aboutoverview/>
        <Ourvalue/>
    </div>
  )
}

export default Aboutus
