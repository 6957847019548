import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './CareerDes.css'

const CareerDes = (props) => {
  return (
    <section className='careerdes-section bg-light' >
        <Container fluid>
            <Row className='w-80 g-0 py-5'>
                <Col className='col-md-10 text-center py-3 mx-auto'>
                    <p className='we-serve-caree-section-para'>{props.paragraph}</p>
                </Col> 
            </Row>
        </Container>
    </section>
  )
}

export default CareerDes
