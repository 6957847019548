// src/ExpandableText.js
import React, { useState } from 'react';

const ExpandableText = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {isExpanded ? text : `${text.substring(0, maxLength)}...`}
      <span onClick={toggleText} style={{ color: '#25635C', cursor: 'pointer' }}>
        {isExpanded ? ' Show less' : ' Read more'}
      </span>
    </>
  );
};

export default ExpandableText;
