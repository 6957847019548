import React from 'react'
import JobOpeningheader from '../component/Jobopeningheader'
import Menu from '../component/Menu'
//import Getintouch2 from '../component/Getintouch2'
import Footer from '../component/Footer'
import Jobdataanalyst from '../component/Jobdataanalyst'
import { Helmet } from 'react-helmet';

const Job2 = ({setBlank}) => {
  <Helmet>
    <title>Data Analyst</title>
    <meta property="og:title" content="Data Analyst" />
    <meta name='og:description' content='Data Analyst'/>
    <meta name='keywords' content=''/>
  </Helmet>

  return (
    <>
        <Menu setBlank={setBlank}  />
        <JobOpeningheader/>
        <Jobdataanalyst />
        <Footer setBlank={setBlank} />

    </>
  )
}

export default Job2
