import React from 'react'
import Menu from '../component/Menu';
import Header from '../component/Header';
import Footer from "../component/Footer";
import { Getintouch } from '../component/Getintouch';
import { Whyus } from '../component/Whyus';
import { Weserve } from '../component/Weserve';
//import { Whowr } from './component/Whowr';
//import { Wherewd } from './component/Wherewd';
import { ArrowSection } from '../component/ArrowSection';
import { Helmet } from 'react-helmet';

const Home = ({setBlank}) => {
  return (
    <>
     <Helmet>
      <title>Home</title>
      <meta property="og:title" content="Home" />
      <meta name='og:description' content='Home'/>
      <meta name='keywords' content=''/>
    </Helmet>
      <Menu setBlank={setBlank} />
      <Header />
      <ArrowSection setBlank={setBlank} />
      <Weserve setBlank={setBlank} />
      <Whyus />
      <Getintouch />
      <Footer setBlank={setBlank} />
    </>
  )
}

export default Home