import React from 'react'
import { useParams } from 'react-router-dom';
import "./footer.css";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap'
import brandlogo from "../images/Brand Logo.webp";

import linkedin from "../images/linkedin.webp";
import instagram from "../images/instagram.webp";

const Footer = ({setBlank}) => {
    let { id } = useParams();  


    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
        setBlank(false);            
        }, 600);
    }

    const d = new Date();
    let year = d.getFullYear();


    return (
        <footer>
            <Container fluid className='p-5 desktop'>
                <Row className="text-center align-center justify-content-center">
                    <Col className='col-md-2'>
                        <NavLink to="/">
                           <img src={id !== "" ? brandlogo : brandlogo } alt='' />
                        </NavLink>
                    </Col>

                    <Col className='md-8 mt-4'>
                        <ul className='footer-menu  align-middle'>
                            <li><NavLink to='/about' onClick={scrollToTop}>About Us</NavLink></li>
                            <li><NavLink to='/service' onClick={scrollToTop}>Services</NavLink></li>
                            <li><NavLink to='/solution' onClick={scrollToTop}>Who We Serve</NavLink></li>
                            <li><NavLink to='/knowledge' onClick={scrollToTop}>Knowledge</NavLink></li>
                            <li><NavLink to='/careers' onClick={scrollToTop}>Careers</NavLink></li>
                            <li><NavLink to='/termsandconditions' onClick={scrollToTop}>Terms & Conditions</NavLink></li>
                            <li><NavLink to='/privacypolicy' onClick={scrollToTop}>Privacy Policy</NavLink></li>
                            <li><NavLink to='/contact' onClick={scrollToTop}>Contact Us</NavLink></li>
                        </ul>
                    </Col>


                    <Col className='col-md-2  mt-4  text-right'>
                        <ul className='social-icons'>
                            <li><a aria-label="LinkedIn" target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/preferred-square-analytics'> <img src={id !== "" ? linkedin : linkedin } alt='' /></a></li>
                            <li><a aria-label="Instagram" target="_blank" rel="noreferrer" href='https://www.instagram.com/preferred_square_analytics/?utm_medium=copy_link'> <img src={id !== "" ? instagram : instagram } alt=''  /></a></li>
                        </ul>
                    </Col>

                </Row>
                <Row>
                    <Col className='col-md-5'><hr className='text-right hr-first' width="90%" align="right" /></Col>
                    <Col className='col-md-2'><p className='copytight-text'>Copyright © {year}</p></Col>
                    <Col className='col-md-5'><hr width="90%" align="right" style={{ textAlign: "right" }} /></Col>
                </Row>

            </Container>
            <Container fluid className='p-5 mobile'>
                <Row className="text-center align-center justify-content-center">
                    <Col className='col-md-12 mx-auto'>
                    <NavLink to='/' onClick={scrollToTop}><img  src={id !== "" ? brandlogo : brandlogo }  alt='' /></NavLink>
                    </Col>
                </Row>
                <Row>

                    <Container className='mt-4 mobile mx-auto'>
                        <Row className='mobile-links'>
                            <Col className='col-md-6 linkleft'>
                                <li><NavLink to='/about' onClick={scrollToTop}>About Us</NavLink></li>
                                <li><NavLink to='/service' onClick={scrollToTop}>Services</NavLink></li>
                                <li><NavLink to='/solution' onClick={scrollToTop}>Who We Serve</NavLink></li>
                                <li><NavLink to='/knowledge' onClick={scrollToTop}>Knowledge</NavLink></li>
                            </Col>
                            <Col className='col-md-6 linkright'>
                                <li><NavLink to='/careers' onClick={scrollToTop}>Careers</NavLink></li>
                                <li><NavLink to='/termsandconditions' onClick={scrollToTop}>Terms & Conditions</NavLink></li>
                                <li><NavLink to='/privacypolicy' onClick={scrollToTop}>Privacy Policy</NavLink></li>
                                <li><NavLink to='/contact' onClick={scrollToTop}>Contact Us</NavLink></li>
                            </Col>
                        </Row>
                    </Container>


                </Row>
                <Row>
                    <Col className='col-md-12  text-right'>
                        <ul className='social-icons'>
                            <li><a aria-label="LinkedIn" target="_blank" rel="noreferrer" href='https://www.instagram.com/preferred_square_analytics/?utm_medium=copy_link'> <img src={id !== "" ? instagram : instagram } alt='' /></a></li>
                            <li className='linkedin-icon'><a aria-label="Instagram" target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/preferred-square-analytics'> <img src={id !== "" ? linkedin : linkedin } alt='' /></a></li>
                        </ul>
                    </Col>

                </Row>
                {/* <Row className='bottom-footer'>
                
                    <Col className='col-md-4'><hr className='text-right hr-first' width="100%" align="right" /></Col>
                    <Col className='col-md-4'><p className='copytight-text'>Copyrighted © 2023</p></Col>
                    <Col className='col-md-4'><hr width="100%" align="right" style={{ textAlign: "right" }} /></Col>
                </Row> */}

                <div className='bottom-footer'>
                    <hr  />
                    <div className=''><p className='copytight-text'>Copyright © {year}</p></div>
                    <hr />
                </div>

            </Container>
        </footer>
    )
}

export default Footer
