import React from 'react'
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody3 from '../component/Casestudybody3';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy3 = ({setBlank}) => {
    const headings = "<h2 style='display:inline-block;'>Streamlining business processes</h2> <h4> and facilitating the implementation of an ERP system</h4>";

  return (
    <>
        <Helmet>
        <title>Streamlining Business Processes And Facilitating The Implementation Of AnERP System</title>
        <meta property="og:image" content="https://preferred-square.pages.dev/static/media/case-study-thumb3.af8d21ca31bdc5161b6d.webp" /> 
        <meta property="og:title" content="Streamlining Business Processes And Facilitating The Implementation Of AnERP System" />
        <meta property='og:description' content='Streamlining Business Processes And Facilitating The Implementation Of AnERP System'/>
        </Helmet>

        <Menu setBlank={setBlank} />
        <HeaderCasestudy headings={headings}  class="header-casestudy3" />  
        <Casestudybody3 />
        <Footer setBlank={setBlank} />
    </>

  )
}

export default CaseStudy3
