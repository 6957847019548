import React from 'react'
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody from '../component/Casestudybody21';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy21 = ({setBlank}) => {
     
    const headings = "<h2 style='display:inline-block;color:#ffffff;'> Corporate Strategy</h2><h4>For A Single-Family Office</h4>";

  return (
    <>
        <Helmet>
        <title>Corporate Strategy For A Single-Family Office</title>
        <meta property="og:title" content="Corporate Strategy For A Single-Family Office" />
        <meta property='og:description' content='Corporate Strategy For A Single-Family Office'/>
        <meta name='keywords' content=''/>
        </Helmet>

        <Menu setBlank={setBlank}  />
        <HeaderCasestudy class="header-casestudy20" headings={headings} />  
        <Casestudybody />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default CaseStudy21
