import React from 'react'
// import Menu from '../component/Menu';
import SingleBody from '../component/SingleBody';
import Getintouch2 from '../component/Getintouch2'
import Footer from '../component/Footer'
import Headersingle from '../component/Headersingle';
// import { Helmet } from 'react-helmet';


const Single = ({setBlank}) => {

  // <Helmet>
  //   <title></title>
  //   <meta name='description' content=''/>
  //   <meta name='keywords' content=''/>
  // </Helmet>

  return (
    <>
       {/* <Menu /> */}
       <Headersingle setBlank={setBlank} />
       <SingleBody /> 
       <Getintouch2 setBlank={setBlank} />
       <Footer setBlank={setBlank} />
  
    </>
  )
}

export default Single
