import React, { useState } from 'react'
import brandlogo from "../images/Brand Logo.png";
import Container from 'react-bootstrap/Container';
//import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Outlet, NavLink } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useParams } from 'react-router-dom';



import './menu.css';

const Menu = ({setBlank}) => {
    let { id } = useParams();  
    let expand = 'md';
    let homeurl = "/";

    const [show, setShow] = useState(false);
    const [showcareer, setShowcareer] = useState(false);

    const [service, setService] = useState('');
    const [career, setCareer] = useState('');


    // const [activecareer, setActivecareer] = useState('');
    // const [activeservice, setActiveservice] = useState('');

    const showDropdown = (e)=>{
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }

    const showCareerDropdown = (e)=>{
        setShowcareer(!showcareer);
    }
    const hideCareerDropdown = e => {
        setShowcareer(false);
    }


    const tolifeps = ()=> {
        setBlank(true);
       setTimeout(() => {
            const lifeps = document.getElementById('lifeps');
            window.scrollTo(0, lifeps.offsetTop);  
            setBlank(false);      
       }, 100);
    }

    const tojobopening = ()=> {
        setBlank(true);
        setTimeout(() => {            
            const jobopening = document.getElementById('jobopening');
            window.scrollTo(0, jobopening.offsetTop);
            setBlank(false);
        }, 100);
    }
    

    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
        setBlank(false);            
        }, 600);
    }


    // const toService = ()=> {
    //     navigate("/service");
    //     setActiveservice('activedropdown');
    // }

    // const tocareer = ()=> {
    //     navigate("/careers");
    //     setActivecareer('activedropdown');
    // }


    // useEffect(()=>{
        
    //     const el = document.getElementById("offcanvasNavbarDropdown-expand-md");
    //     el.addEventListener("click", ()=>{
    //         toService();
    //     }, false);
    
    //     const e2 = document.getElementById("offcanvasNavbarCareerDropdown-expand-md");
    //     e2.addEventListener("click", ()=>{
    //         tocareer();
    //     }, false);
  
        
    // },[activecareer,activeservice,navigate]);
    

  return (
    <>
    <div className='menu'>
        <Container fluid className='hero px-5 pt-3'>   
            <Navbar key={expand} bg="transparent"  expand={expand} className="navbar-dark mb-3">
                <Navbar.Brand href={homeurl}><img src={id !== "" ? brandlogo : brandlogo } alt='Preferred Square | Every Decision Better Informed' /></Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} ></Navbar.Toggle>
                <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                            <NavLink to="/"><img src={id !== "" ? brandlogo : brandlogo }  alt="Logo" /></NavLink>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
              
                        <Nav className="justify-content-end flex-grow-1 pe-3">
              
                            {/* <Link href="about">About Us</Link>
                            <Link href="service">Services</Link>
                            <Link href="whowr">Who We Serve</Link>
                            <Link href="knowledge">Knowledge</Link>
                            <Link href="career">Careers</Link>
                            <Link href="contant">Contact Us</Link> 
  
                            */}


                            <NavLink className="nav-link" to="/about" onClick={scrollToTop} >About Us</NavLink>
                            {/* <NavLink className="nav-link" to="/service">Services</NavLink> */}
                            <NavDropdown 
                                className={`services mobile`}
                                // className={`services ${activeservice} mobile`}
                                // title="Services "
                                title={<NavLink to="/service" onClick={scrollToTop} >Services</NavLink>}
                                // onToggle={() => { window.location.href = '/service'}}
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                show={show}
                                // onClick={()=>{toService();}}
                                onMouseEnter={showDropdown} 
                                onMouseLeave={hideDropdown}  
                            >
                                {/* <NavLink to="/managementconsulting" className="dropdown-item" onClick={()=>{setActiveservice('activedropdown')}}>Management Consulting</NavLink> */}
                                <NavLink to="/managementconsulting" onClick={scrollToTop}  className="dropdown-item">Management Consulting</NavLink>
                                <NavLink to="/investmentadvisory" onClick={scrollToTop}  className="dropdown-item">Investment Advisory</NavLink>
                                <NavLink to="/dataanalytics" onClick={scrollToTop}  className="dropdown-item">Data Analytics</NavLink>
                               
                            </NavDropdown>
                                
                            <div className={`services  nav-item ${service} dropdown desktop`}>
                                <div id="offcanvasNavbarDropdown-expand-md" aria-expanded="true" role="button" className="dropdown-toggle show nav-link" tabIndex="0">
                                 <NavLink to="/service" onClick={scrollToTop} >Services</NavLink>
                                </div>
                                <div
                                 onMouseEnter={()=>{setService('show')}} 
                                 onMouseLeave={()=>{setService('')}} 
                                aria-labelledby="offcanvasNavbarDropdown-expand-md" data-bs-popper="static" className={`dropdown-menu  ${service}`}>

                                <NavLink to="/managementconsulting" onClick={scrollToTop}  className="dropdown-item" >Management Consulting</NavLink>
                                <NavLink to="/investmentadvisory" onClick={scrollToTop}  className="dropdown-item">Investment Advisory</NavLink>
                                <NavLink to="/dataanalytics" onClick={scrollToTop}  className="dropdown-item">Data Analytics</NavLink>
                                    {/* <a aria-current="page" className="dropdown-item " href="/managementconsulting">Management Consulting</a>
                                    <a className="dropdown-item" href="/investmentadvisory">Investment Advisory</a>
                                    <a className="dropdown-item" href="/businessanalytics">Data Analytics</a> */}
                                    </div>
                                </div>
                          
                            <NavLink className="nav-link" onClick={scrollToTop}  to="/solution">Who We Serve</NavLink>
                            <NavLink className="nav-link" onClick={scrollToTop}  to="/knowledge">Knowledge</NavLink>
                            <NavDropdown 
                                // className={`career ${activecareer} mobile`}
                                className={`career mobile`}
                                title={<NavLink to="/careers" onClick={scrollToTop} >Careers</NavLink>}
                                // onToggle={() => { window.location.href = '/service'}}
                                id={`offcanvasNavbarCareerDropdown-expand-${expand}`}
                                show={showcareer}
                                // onClick={toService}
                                onMouseEnter={showCareerDropdown} 
                                onMouseLeave={hideCareerDropdown}  
                            >
                                 <NavLink to="/careers/" onClick={tolifeps} className="dropdown-item">Life @ PS</NavLink>
                                 <NavLink to="/careers/" onClick={tojobopening} className="dropdown-item">Job Openings</NavLink>
                               
                            </NavDropdown>
                      
                      
                            <div className={`career  nav-item ${career} dropdown desktop`}>
                                <div id="offcanvasNavbarDropdown-expand-md" aria-expanded="true" role="button" className="dropdown-toggle show nav-link" tabIndex="0" >
                                 <NavLink to="/careers" onClick={scrollToTop} >Careers</NavLink>
                                </div>
                                  <div
                                    onMouseEnter={()=>{setCareer('show')}} 
                                    onMouseLeave={()=>{setCareer('')}} 
                                    aria-labelledby="offcanvasNavbarDropdown-expand-md" data-bs-popper="static" className={`dropdown-menu  ${career}`}>

                                    <NavLink to="/careers" onClick={tolifeps} className="dropdown-item">Life @ PS</NavLink>
                                    <NavLink to="/careers" onClick={tojobopening}  className="dropdown-item">Job Openings</NavLink>                          
                                   </div>
                                </div>
                          
                      
                            {/* <NavLink className="nav-link" to="/career">Careers</NavLink> */}
                            <NavLink className="nav-link" onClick={scrollToTop} to="/contact">Contact Us</NavLink>
                           
                        </Nav>
                          
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
        </Container> 
    </div>
    <Outlet />
    </>
  )
}

export default Menu