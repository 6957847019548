import React from 'react'
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody from '../component/Casestudybody5';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy5 = ({setBlank}) => {

    const headings = "<h2 style='display:inline-block;color:#ffffff;'> Strategic Investment Blueprint</h2><h4> for a Global Pharma Player </h4>";

  return (
    <>
        <Helmet>
        <title>Strategic Investment Blueprint For A Global Pharma Player</title>
        <meta property="og:title" content="Strategic Investment Blueprint For A Global Pharma Player" />
        <meta property="og:image" content="https://preferred-square.pages.dev/static/media/case-study-client5.e0a527cdc714ba4dbb6a.webp" /> 
        <meta property='og:description' content='Strategic Investment Blueprint For A Global Pharma Player'/>
        <meta name='keywords' content=''/>
        </Helmet>

        <Menu setBlank={setBlank}  />
        <HeaderCasestudy headings={headings} class="header-casestudy5" />  
        <Casestudybody />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default CaseStudy5
