import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../component/Menu'
import HeaderCasestudy from '../component/HeaderCasestudy'
import Casestudybody16 from '../component/Casestudybody16'
import Footer from '../component/Footer'
import thumbimg from '../images/case-study-client16.webp'
    

const CaseStudy16 = ({setBlank}) => {
    
    const headings = "<h2 style='display:inline-block;'>Enterprise Project Management Office </h2><h4>For A Real Estate Development Company</h4>";

  return (
    <>
    <Helmet>
      <title>Enterprise Project Management Office For A Real Estate Development Company</title>
      <meta property="og:image" content={thumbimg} /> 
      <meta property="og:title" content="Enterprise Project Management Office For A Real Estate Development Company" />
      <meta property="og:description" content="Enterprise Project Management Office For A Real Estate Development Company"/>
    </Helmet>

    <Menu setBlank={setBlank} />
    <HeaderCasestudy headings={headings} class="header-casestudy16" />  
    <Casestudybody16 />
    <Footer setBlank={setBlank} />
</>

  )
}

export default CaseStudy16
