import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, useFormik } from 'formik';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isPossiblePhoneNumber }  from 'react-phone-number-input'
import * as Yup from 'yup';
import './jobopeningbody.css'

// const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png","image/webp", ".doc", ".docx", "application/pdf"];
// const SUPPORTED_FORMATS = [".doc", ".docx", "application/pdf"];
const validationSchema = Yup.object().shape({
  name: Yup.string().min(2).max(50).required("Please enter your name").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string().email().min(2).required("Please enter your Email").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email must be a valid email "),
   // phone: Yup.string().min(10).max(10).required("Please enter your Phone Number"),
  message: Yup.string().min(4).required("Please enter message"),
  resume: Yup.mixed().required("Please select resume").test('fileSize', "File is too large", value => value.size <= 3145728)
  // .test('fileType', "Please Select Only doc, docx and pdf file", value => SUPPORTED_FORMATS.includes(value.type) )
  .test("is-valid-type", "Please Select Only doc, docx and pdf file",
  value => isValidFileType(value && value.name.toLowerCase(), "image"))

});

const validFileExtensions = { image: ['doc', 'docx', 'pdf'] };
function isValidFileType(fileName, fileType) {
  return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}

const initialValues = {
  name: "",
  subject: "Data Analyst",
  email: "",
  // phone: "",
  message: "",
  resume: null 
}


const Jobdataanalyst = () => {

  
  const [modalShow, setModalShow] = useState();
  const [response, setResponse] = useState();
  const [phone, setphone] = useState();
  const [phoneerrmsg, setphoneerrmsg] = useState();
  const [hide, setHide] = useState(false);

  const phoneblur = ()=>{ 
    // setphoneerrmsg("Please enter your Phone Number") 
    if (phone === "+91") {
      setphoneerrmsg("Please enter your Phone Number")
    } else {
      setphoneerrmsg("")
    }
  } 


  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: (values, action) => {

        if(formatPhoneNumber(phone) === "") {
          setphoneerrmsg("Please enter your Phone Number")
        } else {
          setphoneerrmsg();

          const formData = new FormData();
          formData.append("name", values.name);
          formData.append("email", values.email);
          formData.append("phone", formatPhoneNumberIntl(phone));
          formData.append("subject", values.subject);
          formData.append("message", values.message);         
          formData.append("resume", values.resume);

        setHide(true); 
        // https://blog.preferred-square.com/wp-json/jobs/jobmail 
        fetch('https://sandboxmachine.com/ps/wp-json/jobs/jobmail', {
          method: 'POST',
          mode: 'cors',
          //  headers: {
          //   //  'Content-Type': 'application/json',
          //    "Content-Type": "multipart/form-data",
          //  },

          body:formData,
          })


            .then(res => res.json())
            .then(data => {
            // enter you logic when the fetch is successful
              setHide(false);
              setModalShow(true);
              console.log(data)
              setResponse(data);
              setTimeout(()=>{
                setResponse("");
              }, 2000);

            })
        
            // .catch(error => {
            // // enter your logic for when there is an error (ex. error toast)
            // console.log(error)
            // }) 

            const fileInput = document.getElementById('file-input');
            if (fileInput) {
              fileInput.value = '';
            }

        setphone();
        action.resetForm();
        }
      },
    });

    return (
        <>
        <section>
          <Container className='jobopeningbody-section'>
            {/* <div className='job-upper-div'>
              <button className='career-btn'>Careers</button>
              <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.5 15L13.5 12L10.5 9" stroke="#151515" />
              </svg></div>
              <p className='job-upper-para'>Data Analyst</p>
            </div> */}
            <Row className='mt-5 pt-lg-5 mb-5'>
              <Col className='col-md-6 jobinfo-section'>
                <h2> Data Analyst</h2>
                <h5 className='mt-5'>Responsibilities</h5>
                <ul className='mt-3'>
  
                <li>Responsible for collaborating with stakeholders to understand and gather business requirements.</li>
                <li>Responsible for collecting, cleaning, and transforming raw data from various sources into structured datasets for analysis. </li>
                <li>Responsible for developing efficient and effective data-gathering methods, ensuring data accuracy, consistency, and completeness.</li>
                <li>Responsible for developing data models, calculations, and formulas to support analysis and reporting requirements.</li>
                <li>Responsible for designing, developing, and deploying and maintaining interactive dashboards, reports, and visualizations using BI tools such as Power BI or Tableau.</li>
                <li>Responsible for optimizing data sources, queries, and performance of Tableau and Power BI solutions; identify and resolve performance bottlenecks to ensure smooth functioning and optimal user experience.</li>
                <li>Responsible for creating and maintaining documentation, including requirements, design specifications, user manuals, and best practices; document data sources, data transformations, and data models to facilitate knowledge sharing and future maintenance.</li>
                <li>Should have hands-on experience in using MS Excel (INDEX, MATCH, VLOOKUP, Pivot Tables, Formatting, Charts, data validation, etc.)</li>
                <li>Experience in writing SQL queries in a plus</li>
                                
               </ul>
                <h5 className='mt-5'>Requirements</h5>
                <ul>
                  <li>Work Ex - 2-3 years</li>
                  <li>Educational Qualifications - Any Graduate</li>
                  <li>Skills Set - MS Excel, Power BI, SQL, Tableau</li>                
                </ul>
              </Col>
              <Col className='col-md-6 form-section'>
                <h2>Apply Now</h2>
                <Formik>
                {() => (
                  <Form onSubmit={handleSubmit} encType="multipart/form-data" className='mt-5'>

                    <div className='mb-3'>
                      <label htmlFor="name" className="">Name*</label>
                      <Field type="text" name="name" placeholder='' aria-labelledby="name" value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      {/* <ErrorMessage name="name" className="" component="div" /> */}
                      <div className='errormsg'> {errors.name && touched.name ? (
                        <p className="form-error">{errors.name}</p>
                      ) : null}</div>
                    </div>
                    <div className='mb-3'>
                      <label htmlFor="email" className="">Email*</label>
                      <Field type="email" name="email" placeholder='' aria-labelledby="email" value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      {/* <ErrorMessage name="email" component="div" /> */}
                      <div className='errormsg'> {errors.email && touched.email ? (
                        <p className="form-error">{errors.email}</p>
                      ) : null}</div>
                    </div>
                    <div className='mb-3'>
                      
                      {/* <label htmlFor="phone" className="">Phone*</label>
                      <Field type="text" name="phone" placeholder='' aria-labelledby="phone" value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      <div className='errormsg'> {errors.phone && touched.phone ? (
                        <p className="form-error">{errors.phone}</p>
                      ) : null}</div> */}

                      <PhoneInput placeholder="" name="phone"  aria-labelledby="phone" value={phone}  country="US"
                         international
                         countryCallingCodeEditable={false}
                         defaultCountry="IN"
                         onChange={setphone}
                         onBlur={phoneblur} 
                         rules={{ required: true, validate: isPossiblePhoneNumber }}
                        /> 

                        <div className='errormsg'> {phoneerrmsg ? (
                        <p className="form-error">{phoneerrmsg}</p>
                        ) : null}</div>

                    </div>

                    <Field type="hidden" name="subject" value="Business Analyst" className="form-control"  /> 

                    <div className='mb-3'>
                      <label htmlFor="message" className="">Message*</label>
                      <Field component="textarea" name="message" row="10" aria-labelledby="message" value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      {/* <ErrorMessage name="message" component="div" /> */}
                      <div className='errormsg'> {errors.message && touched.message ? (
                        <p className="form-error">{errors.message}</p>
                      ) : null}</div>
                    </div>
                    <div className='mb-3'>
                      <label htmlFor="message" className="">Upload Resume*</label>
                      <Field id='file-input' type="file" name="resume" 
                       accept=".doc, .docx,.pdf" 
                       onChange={(event) => {
                        const files = event.target.files[0];
                        setFieldValue("resume", files);
                      }}
                      className='form-control' />
                      <div className='errormsg'> {errors.resume && touched.resume ? (
                        <p className="form-error">{errors.resume}</p>
                      ) : null}</div>
                    </div>

                    <div className='text-left mobile-align'>
                      {(hide) ? (
                      <button type="submit" disabled style={{opacity:"0.5"}}>
                         Apply Now
                      </button>
                      
                      ) :  (
                      <button type="submit" >
                         Apply Now
                      </button>
                        )
                      }
                    </div>

                    {/* <div className="form-success text-center mt-5"    dangerouslySetInnerHTML={{ __html: response }}></div>  */}

                  </Form>
                )}
              </Formik>
              </Col>
            </Row>
          </Container>
                
                 {/* success popup */}
                  <Modal
                        show={modalShow}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        >
                        <Modal.Body className='text-center p-5'>
                            {/* {resonseMsg} */}
                            <div className="form-success text-center mt-5 d-none"    dangerouslySetInnerHTML={{ __html: response }}></div>                           
                            <h5>Thank you for applying at Preferred Square. </h5> <p> We will be reviewing your application and our team will get in touch with you.</p>
                             <button className='close-btn' onClick={() => setModalShow(false)} >Ok</button>
                        </Modal.Body>
                    </Modal>

        </section >
      </>

   )
}

export default Jobdataanalyst
