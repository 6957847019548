import React from 'react'
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody23 from '../component/Casestudybody23';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy23 = ({setBlank}) => {
         
    // const headings = "<h4  style='display:inline-block;color:#ffffff;'>Periodic</h4><h2 style='display:inline-block;color:#ffffff;'>  Portfolio Performance Review</h2><h4>For a US-based PE firm</h4>";
    // const headings = "<h4  style='display:inline-block;color:#ffffff;margin-right:10px;'>Periodic </h4><h2 style='display:inline-block;color:#ffffff;'>  Portfolio Performance</h2><br><h2 style='display:inline-block;color:#ffffff;margin-right:10px;'>Review </h2><h4 style='display:inline-block;color:#ffffff;'>For a US-based PE firm</h4>";
    const headings = "<h2 style='display:inline-block;color:#ffffff;'> Periodic Portfolio Performance Review</h2><h4> For a US-based PE firm</h4>";
  return (
    <>
        <Helmet>
          <title>Periodic Portfolio Performance Review For a US-based PE firm</title>
          <meta property="og:title" content="Periodic Portfolio Performance Review For a US-based PE firm" />
          <meta property='og:description' content='Periodic Portfolio Performance Review For a US-based PE firm'/>
          <meta name='keywords' content=''/>
        </Helmet>
        <Menu setBlank={setBlank}  />
        <HeaderCasestudy headings={headings} class="header-casestudy23" />  
        <Casestudybody23 />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default CaseStudy23
