import React from 'react'
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody from '../component/Casestudybody4';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy4 = ({setBlank}) => {

    const headings = "<h2 style='display:inline-block;color:#ffffff;'>Enabling A Real Estate Conglomerate </h2><h4>To Secure US 40 Mn Funding to Develop A Logistics Park </h4>";

  return (
    <>
        <Helmet>
        <title>Enabling A Real Estate Conglomerate To Secure US 40 Mn Funding to Develop A Logistics Park</title>
        <meta property="og:image" content="https://preferred-square.pages.dev/static/media/case-study-client4.36fb43bb41585c8c3df1.webp" /> 
        <meta property="og:title" content="Enabling A Real Estate Conglomerate To Secure US 40 Mn Funding to Develop A Logistics Park" />
        <meta property="og:description" content="Enabling A Real Estate Conglomerate To Secure US 40 Mn Funding to Develop A Logistics Park" />
      
        </Helmet>

        <Menu setBlank={setBlank}  />
        <HeaderCasestudy class="header-casestudy4" headings={headings} />  
        <Casestudybody />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default CaseStudy4
