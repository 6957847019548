import React, { useEffect ,useState} from 'react'
import ReactPaginate from "react-paginate";
import { Container,Row,Col } from 'react-bootstrap'
import { NavLink } from "react-router-dom";
// import img1 from '../images/insightimg1.webp'
// import img2 from '../images/insightimg2.webp'
import './insight.css'



const BlogList = (props) => {
    // const data = [
    //     {
    //         id: 1,
    //         image: img1,
    //         heading: 'Technology transforming Real Estate Industry',
    //         paragraph: 'The introduction of cutting-edge technologies in real estate promises to change…',
    //         bttn: 'Find out more about us'
    //     },
    //     {
    //         id: 2,
    //         image: img2,
    //         heading: 'Technology transforming Real Estate Industry',
    //         paragraph: 'The introduction of cutting-edge technologies in real estate promises to change…',
    //         bttn: 'Find out more about us'
    //     },
    // ]


    
    function truncateString(string, maxLength) {
        const words = string.split(' ');
        const truncatedWords = words.slice(0, maxLength);
        return truncatedWords.join(' ');
    }

    const scrollToTop = () => {
        props.setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
            props.setBlank(false);            
        }, 600);
    }
    

      const scrollTop = () => {
        setTimeout(() => {
            window.scrollTo(0, window.innerHeight / 2)
            }, 100);
      }

    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(8);
    
    // const [postcount, setPostcount] = useState(1);
    
    const postPerPage = 9;
    
    // const pagesVisited = page * usersPerPage;
    const pageCount = Math.ceil(total / postPerPage);
    const changePage = ({ selected }) => {
        // setPageNumber(selected);
        scrollTop();
        setPage(selected+1);
    };


    useEffect(() => {
        
        if (props.type === 'Insights' ) {
            // fetch('https://sandboxmachine.com/ps/wp-json/insights/v1/posts?page='+page +'&&post_status=publish', { method: "GET"})
            fetch('https://blog.preferred-square.com/wp-json/insights/v1/posts?page='+page +'&&post_status=publish', { method: "GET"})
            // fetch('https://blog.preferred-square.com/wp-json/insights/v1/posts?page='+page, { method: "GET"})
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                setPosts(data);
                setTotal(data[0].total);
            });
        } else if (props.type === 'Blogs') {
            fetch('https://blog.preferred-square.com/wp-json/blogs/v1/posts?page='+page +'&&post_status=publish', { method: "GET"})
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                setPosts(data);
                setTotal(data[0].total);
            });
        }

    }, [page,props.type]);




    return (
        <>
            <Container className='insight-section'>
                <Row>
                    {
                        posts.map((d) => {

                            const htmlStr = d.post_content;
                            const newStr = htmlStr.replace(/(<([^>]+)>)/ig, '');
                            // const short = newStr.substring(0, 80).replace(/&nbsp;/gi," ");
                            const short = truncateString(newStr, 10);
                            
                            const htmlStr1 = d.post_title;
                            const newStr1 = htmlStr1.replace(/(<([^>]+)>)/ig, '');
                            // const title = newStr1.substring(0, 50).replace(/&nbsp;/gi," ");
                            const title = truncateString(newStr1, 8);
                            const url = d.post_title.toLowerCase().replace(/(<([^>]+)>)/ig, '').replace("/", "").replace(/ /g, '-');
                     
                            return (
                                <Col  key={d.ID} className='col-md-4 mt-4 mb-5 pb-4'>
                                   <div className='p-2'>
                                   <div>
                                   <NavLink onClick={scrollToTop} to={`/single/${d.ID}/${url}`} >
                                        <img src={d.thumbnail} alt='' />
                                    </NavLink>
                                    </div>
                                    <div>
                                    <NavLink onClick={scrollToTop} to={`/single/${d.ID}/${url}`} >
                                    <h2 className='mt-4'>{newStr1.length <= title.length  ? title : title+'...' }</h2>
                                        <p className='mt-4 pb-2'>{short}...</p>
                                        <p className='anima'><span className='anima-underline' > **** </span></p>
                                    </NavLink>
                                    </div>
                                    <NavLink onClick={scrollToTop} to={`/single/${d.ID}/${url}`} >
                                        <button className='mt-5'>Read more</button>
                                    </NavLink>    
                                   </div>
                                </Col>
                            )
                        })
                    }
                </Row>
                

                <Row className='pagination mt-5'>
                    <Col className='col-md-6 ms-auto text-right'>
                        <ReactPaginate
                        // previousLabel={<img src={previousp} alt='' />}
                        // nextLabel={<img src={nextp} alt='' />}
                        previousLabel="<"
                        nextLabel=">"
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                        />
                    </Col>     
                </Row>
            </Container>
        </>
    )
}

export default BlogList