import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../component/Menu'
import HeaderCasestudy from '../component/HeaderCasestudy'
import Casestudybody18 from '../component/Casestudybody18'
import Footer from '../component/Footer'
import thumbimg from '../images/case-study-client18.webp'
    

const CaseStudy18 = ({setBlank}) => {
    
    const headings = "<h2>Investment Memorandum </h2><h4>for an Investment Firm</h4>";

  return (
    <>
    <Helmet>
      <title>Investment Memorandum for an Investment Firm</title>
      <meta property="og:image" content={thumbimg} /> 
      <meta property="og:title" content="Investment Memorandum for an Investment Firm" />
      <meta property="og:description" content="Investment Memorandum for an Investment Firm"/>
    </Helmet>

    <Menu setBlank={setBlank} />
    <HeaderCasestudy headings={headings} class="header-casestudy18" />  
    <Casestudybody18 />
    <Footer setBlank={setBlank} />
</>

  )
}

export default CaseStudy18
