import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './casestudybody.css';

import clientimg from '../images/case-study-client.webp'
import outcome from '../images/outcome.webp'
import outcome1 from '../images/out-come1.webp'
import outcome2 from '../images/out-come2.webp'

import metho1 from '../images/metho1.webp'
import metho2 from '../images/metho2.webp'
import metho3 from '../images/metho3.webp'

import step1 from '../images/mstep1.webp'
import step2 from '../images/mstep2.webp'
import step3 from '../images/mstep3.webp'
import step4 from '../images/mstep4.webp'
import step5 from '../images/mstep5.webp'

import sm1 from '../images/cs1so1.webp'
import sm2 from '../images/cs1so2.webp'
import sm3 from '../images/cs1so3.webp'
import sm4 from '../images/cs1so4.webp'
import sm5 from '../images/cs1so5.webp'

const Casestudybody = () => {

  // const scrollToTop = () => {
  //   window.scrollTo(0, 0)
  // }

  // const scrollTop = () => {
  //   window.scrollTo(0, window.innerHeight / 2)
  // }


  return (
    <>
    <section className='casestudybody-section'  >
      <Container>
      {/* <Row className='w-100 pt-md-5 pt-5'>
        <div className='col-md-12 '>
        <ul class="breadcrumb">
          <li className='last'><NavLink onClick={scrollTop} to={`/knowledge`} >
                 Knowledge </NavLink></li>
          <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M10.5 15L13.5 12L10.5 9" stroke="#151515"/>
            </svg></li>
          <li>Case Study 1</li>
          </ul>
        </div>
      </Row>       */}
        <Row className='w-100 py-md-5 py-4 clintrow'>
            <div className='col-md-4 relative order-1'>
              <div className='left-box'>
                <div className='content-box'>
                  <h2>About</h2>
                  {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                  <div className="h1">The Client</div>
                  <p className='anima '><span className='only-anima-underline' > **** </span></p>
                  <p>The client is a US-based private equity firm with investments in primarily three asset classes - credit, real assets and listed equities.</p>
                </div>
              </div>
                       
            </div>
            <div className='col-md-8 right-box'>
                <img src={clientimg} alt="" />
            </div>
        </Row>
   
        <Row className='mt-md-5'>
          <div className='col-md-12'>
            <div className='content-box'>
              <h2>Situation</h2>
              {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
              <div className="h1">Overview</div>
              <p className='anima '><span className='only-anima-underline' > **** </span></p>
              <p>The firm possessed USD 1.5 Billion in available capital for investments, with an investment mandate to target opportunities in technology, retail and consumer durable sectors. The firm was interested in assessing potential prospects for investment primarily via an LBO.</p>
            </div>
            
          </div>
        </Row>
      </Container>
    </section>

    <section className='casestudybody-section bg-gredient-dark'>
      <Container>
        <Row>
          <div className='col-md-12'>
            <div className='content-box'>
                <h2>Approach &</h2>
                {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                <div className="h1">Methodology</div>
                <p className='anima '><span className='only-anima-underline' > **** </span></p>
                <p>The first step in our engagement was to put together a step-by-step framework that would enable us to fast-track the assessment process and identify those investment opportunities that were most aligned with the firm’s exit goals.</p>
                <p><i>Preferred Square 5-step framework</i></p>
                <ul className='ps-steps'>
                  <li>
                    <img src={step1} alt='' />
                    <div className='text'>
                      <p>Assess potential <br /> targets </p>
                    </div>
                  </li>
                  <li>
                    <img src={step2} alt='' />
                    <div className='text'>
                      <p>Conduct due <br /> diligence </p>
                    </div>
                  </li>
                  <li>
                    <img src={step3} alt='' />
                    <div className='text'>
                      <p>Structure the <br /> transaction </p>
                    </div>
                  </li>
                  <li>
                    <img src={step4} alt='' />
                    <div className='text'>
                      <p>Create valuation &<br />exit strategy </p>
                    </div>
                  </li>
                  <li>
                    <img src={step5} alt='' />
                    <div className='text'>
                      <p>Assess returns </p>
                    </div>
                  </li>
                </ul>

                <p>To commence the evaluation process, we conducted a kick-off meeting to assess the primary roster of potential LBO targets provided by the client. Our assessment took into account various factors including future growth potential, cash flows, market position, and opportunities for operational enhancements. </p>

                <p>After assessing the initial roster, we conducted a comprehensive due diligence on the target company. The due diligence involved evaluating the financial statements, operations, legal and regulatory compliance, customer base, and competitive landscape of the target company.</p>

                <p>The next step was to determine the appropriate structure for executing the transaction. We compared multiple funding scenarios in accordance with the debt covenants and defined the terms for interest rates and repayment schedules for senior, subordinated and junior debt.</p>

                <p>After the execution structure, we identified the appropriate price for the target companies based on different valuation structures. We also created an exit plan that took into consideration the following</p>


                <div className='list-text my-3'>
                    <div className='icon'>
                      <img src={metho1} alt="" />
                    </div>
                    <div className='text'>
                      <p>Gains from operational improvements</p>
                    </div>
                  </div>

                  <div className='list-text my-3'>
                    <div className='icon'>
                      <img src={metho2} alt="" />
                    </div>
                    <div className='text'>
                      <p>Expansion </p>
                    </div>
                  </div>

                  <div className='list-text my-3'>
                    <div className='icon'>
                      <img src={metho3} alt="" />
                    </div>
                    <div className='text'>
                      <p>Trading on equity</p>
                    </div>
                  </div>

                  <p className='mt-4'>Finally, we determined the investment value and expected value at the time of exit to assess the expected returns from the transaction.</p>
              
                  <h3 className='mt-5'>Sample Output</h3>
                  <div className='sample-output mt-3'>
                    <img src={sm1} alt=''/>
                    <img src={sm2} alt=''/>
                    <img src={sm3} alt=''/>
                    <img src={sm4} alt=''/>
                    <img src={sm5} alt=''/>
                  </div>
           
              </div>
              
            </div>
        </Row>
      </Container>
    </section>

    <section className='casestudybody-section outcome'  >
      <Container>
        <Row className='w-100 pb-5 clintrow '>
            <div className='col-md-4 relative order-1'>
              <div className='left-box'>
                <div className='content-box'>
                  <div className="h1">Outcome</div>
                  <p className='anima '><span className='only-anima-underline' > **** </span></p>

                  <div className='list-text'>
                    <div className='icon'>
                      <img src={outcome1} alt="" />
                    </div>
                    <div className='text'>
                      <p>Leveraging our financial models, we enabled the client to expedite the evaluation of numerous LBO investment opportunities. </p>
                    </div>
                  </div>
                  <div className='list-text'>
                    <div className='icon'>
                      <img src={outcome2} alt="" />
                    </div>
                    <div className='text'>
                      <p>We assisted the client in allocating USD 800 Million from their available capital, with an anticipated exit multiple of 5.40x.</p>
                    </div>
                  </div>

                </div>
              </div>
                       
            </div>
            <div className='col-md-8 right-box'>
                <img src={outcome} alt="" />
            </div>
        </Row>
   
      </Container>
    </section>

    </>  
  )
}

export default Casestudybody
