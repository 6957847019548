import React from 'react'
import Menu from '../component/Menu';
import Headercareer from '../component/Headercareer'
import CareerDes from '../component/CareerDes'
import WeServeCareer from '../component/WeServeCareer'
import { LifeAtPs } from '../component/LifeAtPs';
// import Careerbody from '../component/Careerbody'
// import Careerjob from '../component/Careerjob'
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';
import JobOpeningsSection from '../component/JobOpeningsSection';
import Testimonial from '../component/Testimonial';
// import TestTestimonial1 from '../component/TestTestimonial1';

  

const Career = ({setBlank}) => {
  const paragraph="We believe in creating an environment where ambitious professionals get an opportunity to create meaningful impact. You will get an opportunity to collaborate with a team of industry experts on transformative and innovative projects."

  return (
    <>  
    <Helmet>
      <title>Career</title>
      <meta property="og:title" content="Career" />
      <meta property="og:description" content="Career"/>
    </Helmet>
        <Menu setBlank={setBlank} />
        <Headercareer />   
        <CareerDes paragraph={paragraph} />
        <WeServeCareer />
        <LifeAtPs />
        <Testimonial />
        {/* <TestTestimonial1 /> */}
        <JobOpeningsSection setBlank={setBlank} />
        {/* <Careerbody /> */}
        {/* <Careerjob /> */}
        <Footer setBlank={setBlank} />
    </>
      
  )
}

export default Career
