import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './casestudybody5.css';

import clientimg from '../images/case-study-client5.webp'
import outcome from '../images/outcome5.webp'

import sm1 from '../images/cs5so1.webp'
import sm2 from '../images/cs5so2.webp'
import sm3 from '../images/cs5so3.webp'
import sm4 from '../images/cs5so4.webp'
import sm5 from '../images/cs5so5.webp'
import sm6 from '../images/cs5so6.webp'



const Casestudybody5 = () => {

    // const scrollTop = () => {
    //     window.scrollTo(0, window.innerHeight / 2)
    //   }

    return (
        <>
        <section className='casestudybody5-section'  >
          <Container>
          {/* <Row className='w-100 pt-md-5 pt-5'>
            <div className='col-md-12 '>
            <ul class="breadcrumb">
              <li className='last'><NavLink onClick={scrollTop} to={`/knowledge`} >
                     Knowledge </NavLink></li>
              <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.5 15L13.5 12L10.5 9" stroke="#151515"/>
                </svg></li>
              <li>Case Study 1</li>
              </ul>
            </div>
          </Row>       */}
            <Row className='w-100 py-md-5 py-4 clintrow'>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <h2>About</h2>
                      {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                      <div className="h1">The Client</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>The client operates as a licensed Biopharmaceutical manufacturing and marketing firm. They are committed to leading patient-centric care by addressing significant gaps in the biopharma products landscape. </p>
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={clientimg} alt="" />
                </div>
            </Row>
       
            <Row className='mt-md-5'>
              <div className='col-md-12'>
                <div className='content-box'>
                  <h2>Situation</h2>
                  {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                  <div className="h1">Overview</div>
                  <p className='anima '><span className='only-anima-underline' > **** </span></p>
                  <p>Our client was among the first entities venturing into the Saudi manufacturing market which is a relatively nascent industry. Their objective was to launch a Greenfield Project, focused on establishing a specialized biopharma research and production facility.</p>
                  <p>For this, they sought a well-drafted fundraising collateral package encompassing an Investor Kit & Business Plan. The project was backed by several local government entities that provided certain funding. However, additional funding was required to initialize Phase 1. The primary aim was to highlight the unique value of the project and emphasize its potential to offer substantial returns to potential investors.</p>
                </div>
                
              </div>
            </Row>
          </Container>
        </section>
    
        <section className='casestudybody5-section bg-gredient-dark'>
          <Container>
            <Row>
              <div className='col-md-12'>
                  <div className='content-box'>
                    <h2>Approach &</h2>
                    {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                    <div className="h1">Methodology</div>
                    <p className='anima '><span className='only-anima-underline' > **** </span></p>
                    <p>The next step was to put together and implement a step-by-step process for conducting the valuation:</p>
                  
                    <div className='list-text my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>
                        </div>
                        <div className='text'>
                          <h4>Market Assessment:</h4>
                          <p>Undertook a holistic evaluation of the regional Pharmaceutical market. This covered key facets like demand dynamics, governmental policy impacts, market challenges, among others. While the client had substantial market insights, they needed expert assistance to solidify their foundational understanding.</p>
                        </div>
                      </div>
    
                      <div className='list-text my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>
                        </div>
                        <div className='text'>
                          <h4>Business Plan Formulation</h4>
                          <p>Crafted a strategic 10-year business trajectory, considering three distinct scenarios—conservative, optimal, and aspirational. Variables like market share dynamics, R&D success probabilities, operational downtime, and potential business streams were integrated into this plan.</p>
                        </div>
                      </div>
    
                      <div className='list-text last-child my-3'>
                        <div className='icon'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11" fill="#03A393" stroke="white"/>
                        </svg>
                        </div>

                        <div className='text'>
                          <h4>Benchmarking Analysis</h4>
                          <p>Conducted a rigorous benchmarking analysis of global competitors. This concentrated on pivotal metrics like product pricing paradigms, revenue growth trajectories, gross profit margins, and EBITDA margins. The objective was to juxtapose these key performance indicators with the anticipated margins from the novel production site.</p>
                        </div>
                      </div>

                     
                      <h3 className='mt-5'>Sample Output</h3>
                      <div className='sample-output mt-3'>
                        <img src={sm1} alt=''/>
                        <img src={sm2} alt=''/>
                        <img src={sm3} alt=''/>
                        <img src={sm4} alt=''/>
                        <img src={sm5} alt=''/>
                        <img src={sm6} alt=''/>
                      </div>
                  </div>
                  
                </div>
            </Row>
          </Container>
        </section>

    
        <section className='casestudybody5-section outcome'  >
          <Container>
            <Row className='w-100 pb-5 clintrow '>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <div className="h1">Outcome</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>Preferred Square developed a detailed Investment Deck encapsulating both the strategic vision and commercial potential of the project. Representing the client, Preferred Square presented this Deck to potential investors, successfully securing additional funding of USD 20 Mn to initiate the project's first phase.</p>  
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={outcome} alt="" />
                </div>
            </Row>
       
          </Container>
        </section>
    
        </>  
      )
    }

export default Casestudybody5
