import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../component/Menu'
import HeaderCasestudy from '../component/HeaderCasestudy'
import Casestudybody15 from '../component/Casestudybody15'
import Footer from '../component/Footer'
import thumbimg from '../images/case-study-client15.webp'
    

const CaseStudy15 = ({setBlank}) => {
    
  const headings = " <h2 style='display:inline-block'>Funding Strategy </h2><h4>For A Real Estate Development Subsidiary</h4>";

  return (
    <>
    <Helmet>
      <title>Funding Strategy For A Real Estate Development Subsidiary</title>
      <meta property="og:image" content={thumbimg} /> 
      <meta property="og:title" content="Funding Strategy For A Real Estate Development Subsidiary" />
      <meta property="og:description" content="Funding Strategy For A Real Estate Development Subsidiary"/>
    </Helmet>

    <Menu setBlank={setBlank} />
    <HeaderCasestudy headings={headings} />  
    <Casestudybody15 />
    <Footer setBlank={setBlank} />
</>

  )
}

export default CaseStudy15
